import React, { useContext } from 'react'

import { isNil } from 'lodash'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import CompletionRateCard from 'components/Insights/ResponseRate/CompletionRateCard'
import EmployeeResponseRateCard from 'components/Insights/ResponseRate/EmployeeResponseRateCard'
import ResponseRateCard from 'components/Insights/ResponseRate/ResponseRateCard'
import { useTimeTrendingTimeframeSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { getShowReportBy } from 'components/Survey/Wizard/SurveyResponseRateCard'
import { StoreContext } from 'config/LocalStorage'
import {
  useInsightsResponseRateQuery,
  SurveyProductTypeEnum,
  ResponseRateTypeEnum,
  SurveyDistributionTypeEnum,
  SurveyTypeEnum,
} from 'generated/graphql'
import { useTimeframeSettings } from 'utils/customHooks'

type Props = {
  survey: InsightsSurvey
  filterValueUuids: string[]
  hasTimeTrending?: boolean
  showTitle?: boolean
}
const ResponseRateContainer: React.FC<Props> = ({
  survey,
  filterValueUuids,
  hasTimeTrending = false,
  showTitle = true,
}) => {
  const timeframeSettings = useTimeframeSettings(survey)
  const { timeTrendingEnabled, timeRanges } = useTimeTrendingTimeframeSettings(survey)
  let startDate = timeframeSettings.startDate
  let endDate = timeframeSettings.endDate
  // When using timeframe time-trending, check responses across the full time range.
  if (timeTrendingEnabled) {
    startDate = timeRanges[0].startDate
    endDate = timeRanges[timeRanges.length - 1].endDate
  }

  const {
    store: { responseRateShowReportBy },
  } = useContext(StoreContext)

  let showReportBy = ResponseRateTypeEnum.PARTICIPANT
  if (
    survey.productType === SurveyProductTypeEnum.RESIDENT &&
    ![SurveyTypeEnum.RESIDENT_END_OF_SERVICE, SurveyTypeEnum.RESIDENT_DISCHARGE].includes(
      survey.type,
    )
  ) {
    showReportBy = getShowReportBy(
      responseRateShowReportBy,
      survey.uuid,
      survey.isDefaultClientBasedResponseRateReport,
    )
  }

  // We're separating the InsightsSurveyNode query from the SurveyNode query because the former
  // refers to insights related calculations and is cached by query arguments. We don't want to refetch
  // the fields of SurveyNode when query arguments change because that would be inefficient.
  const result = useInsightsResponseRateQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters: filterValueUuids,
      startDate,
      endDate,
      // Use `byParticipant` since insights results are returned by participant
      // and we use this number to determine less than min_showable responses.
      byClient: showReportBy === ResponseRateTypeEnum.CLIENT,
    },
    // Debounce query updates when filters change by adding a debounce key at the root query
    // of insights.
    context: {
      // Requests get debounced together if they share the same debounceKey.
      // Requests without a debounce key are passed to the next link unchanged.
      debounceKey: filterValueUuids.length ? 'ResponseRateContainer' : null,
      debounceTimeout: 3000,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({
        survey: {
          insightsSurvey: { responseRate, completionRate },
        },
      }) => {
        if (
          [SurveyTypeEnum.TI, SurveyTypeEnum.PULSE].includes(survey.type) ||
          (survey.type === SurveyTypeEnum.CUSTOM && isNil(endDate))
        ) {
          return (
            <EmployeeResponseRateCard
              survey={survey}
              hasTimeTrending={hasTimeTrending}
              finished={responseRate.finished}
              total={responseRate.total}
              filters={filterValueUuids}
            />
          )
        }

        if (
          survey.isRecurring ||
          survey.distributionType === SurveyDistributionTypeEnum.OPEN ||
          [SurveyTypeEnum.CUSTOM].includes(survey.type)
        ) {
          return (
            <CompletionRateCard
              {...completionRate}
              survey={survey}
              startDate={startDate}
              endDate={endDate}
            />
          )
        }

        return (
          <ResponseRateCard
            showTitle={showTitle}
            hasTimeTrending={hasTimeTrending}
            surveyUuid={survey.uuid}
            showReportBy={showReportBy}
            minShowableResults={survey.minShowableResults}
            finished={responseRate.finished}
            total={responseRate.total}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default ResponseRateContainer
