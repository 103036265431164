import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import NpsBreakdownCard from 'components/Insights/Blocks/NPS/NpsBreakdownCard'
import NpsRecommendCard from 'components/Insights/Blocks/NPS/NpsRecommendCard'
import RankedStatementsCard from 'components/Insights/Blocks/RankedStatementsCard'
import CoreQBreakdownCard from 'components/Insights/Discharge/CoreQBreakdownCard'
import CoreQResultsCard from 'components/Insights/Discharge/CoreQResultsCard'
import OverallRecommendCard from 'components/Insights/Discharge/OverallRecommendCard'
import SummaryCard from 'components/Insights/Discharge/SummaryCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import KeyDemographics from 'components/Insights/ResidentSnapshot/KeyDemographicsCard'
import ResponseRateContainer from 'components/Insights/ResponseRate/ResponseRateContainer'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import TimeframeCompletionRateCard from 'components/Insights/TimeframeTimeTrending/TimeframeCompletionRateCard'
import TimeframeCoreQResultsCard from 'components/Insights/TimeframeTimeTrending/TimeframeCoreQResultsCard'
import TimeframeDetailedBreakdownCard from 'components/Insights/TimeframeTimeTrending/TimeframeDetailedBreakdownCard'
import TimeframeKeyDemographicsCard from 'components/Insights/TimeframeTimeTrending/TimeframeKeyDemographicsCard'
import TimeframeRecommendationScoreCard from 'components/Insights/TimeframeTimeTrending/TimeframeRecommendationScoreCard'
import TimeframeStatementsCard from 'components/Insights/TimeframeTimeTrending/TimeframeStatementsCard'
import { useTimeTrendingTimeframeSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import {
  BenchmarkCodeType,
  useInsightsDischargeOverviewQuery,
  DataTypeCode,
} from 'generated/graphql'
import { ORDER_TYPES, CORE_Q1_RECOMMEND_QUESTION_TEXT } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  commentsLink: {
    '& >p': {
      display: 'inline-block',
    },
  },
  tooltip: {
    padding: spacing(2),
  },
}))

const DischargeSnapshot: React.FC<InsightsTabProps> = props => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const { survey, filters, startDate, endDate, benchmark, solution } = props

  const hasAccessToExtendedSnapshot = solution.insightsSnapshots

  const { timeTrendingEnabled, timeRanges } = useTimeTrendingTimeframeSettings(survey)
  const result = useInsightsDischargeOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      startDate,
      endDate,
      benchmark,
    },
  })

  return (
    <ResponseHandler {...result}>
      {({ insightsStatements }) => {
        const positiveStatements = orderBy(
          insightsStatements,
          ['positive', 'label'],
          ORDER_TYPES.DESCENDING,
        )
        const negativeStatements = orderBy(
          insightsStatements,
          ['negative', 'label'],
          ORDER_TYPES.DESCENDING,
        )
        // Discharge surveys can ask one of Core_Q1_recommend, NPS question, or both.
        // We need to handle when either are missing.
        const recommendStatement = insightsStatements.find(
          stmt => stmt.code === BenchmarkCodeType.CORE_Q1_RECOMMEND,
        )
        if (timeTrendingEnabled && timeRanges) {
          const timeTrendingProps = { survey, filters, startDate, endDate, benchmark, timeRanges }
          return (
            <>
              <div className={classes.fullRow}>
                <TimeframeCompletionRateCard {...timeTrendingProps} />
              </div>
              {recommendStatement && (
                <>
                  <div className={classes.fullRow}>
                    <TimeframeRecommendationScoreCard {...timeTrendingProps} />
                  </div>
                  <TimeframeDetailedBreakdownCard
                    {...props}
                    timeRanges={timeRanges}
                    scoreName="Recommendation Score"
                  />
                </>
              )}
              <TimeframeStatementsCard {...timeTrendingProps} statements={positiveStatements} />
              <TimeframeKeyDemographicsCard {...timeTrendingProps} />
              <TimeframeCoreQResultsCard {...timeTrendingProps} />
              <TimeframeDetailedBreakdownCard
                {...props}
                scoreName="Core Q"
                timeRanges={timeRanges}
                statementCodes={[BenchmarkCodeType.CORE_Q_SUMMARY]}
                scoreTitle="CoreQ Satisfaction Score"
                // Limit to Locations
                visibleFilterTypes={props.visibleFilterTypes.filter(
                  ft => ft.dtCode === DataTypeCode.AI_LOCATION,
                )}
                tooltipText="See how your CoreQ satisfaction scores compare for each location, by time period within the selected time frame."
              />
            </>
          )
        }

        return (
          <>
            <div className={classes.halfRow}>
              <SummaryCard survey={survey} />
              <ResponseRateContainer survey={survey} filterValueUuids={filters} showTitle={false} />
            </div>
            {recommendStatement && (
              <>
                <div className={classes.fullRow}>
                  {recommendStatement.count > 0 ? (
                    <OverallRecommendCard statement={recommendStatement} {...props} />
                  ) : (
                    <EmptyState
                      title=""
                      description="No responses received during this period from your selected filters"
                      Icon={ChartUpwardIcon}
                    />
                  )}
                </div>

                <DetailedBreakdownCard
                  {...props}
                  title="Overall Recommendation Score By"
                  useFilterNameSuffixInTitle
                  description={
                    <>
                      We are using the statement, “{CORE_Q1_RECOMMEND_QUESTION_TEXT}” as the key
                      indicator for how participants feel. The percent represents the Overall
                      Recommendation Score for each location.
                      <br />
                    </>
                  }
                  tooltip={
                    <div className={classes.tooltip}>
                      <Typography>Recommendation Question:</Typography>
                      <Typography color="textSecondary">
                        Respondents are given a linear scale rating (1 to 5) to answer the question.
                        Positive responses (4-5), Inconsistent responses (3), and Negative responses
                        (1-2). The Recommendation score is the percentage of satisfied customers who
                        gave a positive response to the question.
                      </Typography>
                    </div>
                  }
                  hasTimeTrending={false}
                  overallPositiveScore={recommendStatement.positive}
                  benchmarkPositive={recommendStatement.benchmarkPositive || 0}
                  statementCode={BenchmarkCodeType.CORE_Q1_RECOMMEND}
                  startDate={startDate}
                  endDate={endDate}
                  breakdownModalScoreTitle="Recommendation Score"
                />
              </>
            )}
            <div className={classes.fullRow} id="top-statements-snap">
              <RankedStatementsCard
                {...props}
                scoreKey="positive"
                benchmarkScoreKey="benchmarkPositive"
                statements={positiveStatements.slice(0, 2)}
                title="Strengths"
                description="There is a lot to celebrate! These are the statements with the highest percentage of positive responses. Make sure your team knows these statements are their greatest strengths."
                tooltipText={
                  <div className={classes.tooltip}>
                    <Typography>Statement Scores</Typography>
                    <Typography color="textSecondary">
                      The statement questions use a 5-point scale: Positive responses (4-5),
                      Inconsistent responses (3), and Negative responses (1-2) This is an aggregate
                      score showing how many respondents answered positively on each statement
                      question.
                    </Typography>
                    <br />
                    <Typography>How is the Statement Score calculated?</Typography>
                    <Typography color="textSecondary">
                      To calculate the statement score we take the number of positive responses, and
                      divide them by the total number of responses.
                    </Typography>
                  </div>
                }
                snapId="top-statements-snap"
              />
            </div>
            <div className={classes.fullRow} id="bottom-statements-snap">
              <RankedStatementsCard
                {...props}
                statements={negativeStatements.slice(0, 2)}
                scoreKey="negative"
                benchmarkScoreKey="benchmarkNegative"
                title="Opportunities"
                description="These are the statements with the highest percentage of negative responses. Improving these statement scores will help to enhance the patient experience overall."
                tooltipText={
                  <div className={classes.tooltip}>
                    <Typography>Statement Scores</Typography>
                    <Typography color="textSecondary">
                      These are your lowest scoring statements. The statements are ranked in this
                      section by highest negative responses, meaning patients that answered with a
                      negative score of 1 or 2. These statements represent your greatest
                      opportunities for growth and improvement.
                    </Typography>
                    <br />
                    <Typography>How is the Statement Score calculated?</Typography>
                    <Typography color="textSecondary">
                      To calculate the statement score we take the number of positive responses, and
                      divide them by the total number of responses.
                    </Typography>
                  </div>
                }
                snapId="bottom-statements-snap"
              />
            </div>
            <KeyDemographics
              survey={survey}
              filters={filters}
              benchmark={benchmark}
              surveyProductType={survey.productType}
              hasTimeTrending={false}
              tooltip={
                <div className={classes.tooltip}>
                  These charts show the overall satisfaction for each demographic group. We are
                  using the recommendation question as a key data point to help your organization
                  measure satisfaction across different groups. Focus on reducing variability to
                  ensure a consistent positive experience.
                </div>
              }
            />
            <div className={classes.fullRow} id="core-q-results-summary">
              <CoreQResultsCard survey={survey} filters={filters} />
            </div>
            <CoreQBreakdownCard {...props} startDate={startDate} endDate={endDate} />
            {survey.includesNpsQuestion && (
              <>
                <NpsRecommendCard
                  survey={survey}
                  productType={survey.productType}
                  filters={filters}
                  useFormPanel
                  showCommentsLink={hasAccessToExtendedSnapshot}
                />
                {hasAccessToExtendedSnapshot && (
                  <NpsBreakdownCard
                    isSnapshotNps
                    survey={survey}
                    filterTypes={props.visibleFilterTypes}
                    filters={filters}
                  />
                )}
              </>
            )}
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default DischargeSnapshot
