import React, { useState, useEffect } from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Loader from 'components/Blocks/Loader'
import Comments from 'components/Insights/Comments'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import {
  useInsightsCommentsQuery,
  useInsightsCommentsTopCommentsQuery,
  InsightsCustomSurveySnapshotQuery,
  BenchmarkCodeType,
  NpsGroupsEnum,
} from 'generated/graphql'

interface Props {
  survey: InsightsSurvey
  question: NonNullable<InsightsCustomSurveySnapshotQuery['insightsCustomSurvey']>[0]['question']
  enhancedComments: boolean
  filters: string[]
  startDate?: string
  endDate?: string
}

const CustomSurveyComments: React.FC<Props> = ({
  survey,
  question,
  filters,
  startDate,
  endDate,
  enhancedComments,
}) => {
  const pageSize = 20
  const [page, setPage] = useState(0)
  const [filterBy, setFilterBy] = useState<null | NpsGroupsEnum>(null)
  const [filtersHaveChanged, setFiltersHaveChanged] = useState(false)
  useEffect(() => {
    setFiltersHaveChanged(true)
  }, [filters])
  const [searchWords, setSearchWords] = useState<string[]>([])
  const commonVars = {
    surveyUuid: survey.uuid,
    questionCode: question.uuid,
    filters,
    startDate,
    endDate,
    // Include the manual search query in addition to words the user selects off the word cloud chart.
    searchWords,
    sortBy: '-score',
    filterBy,
  }

  const result = useInsightsCommentsQuery({ variables: { ...commonVars, page, pageSize } })
  const topResult = useInsightsCommentsTopCommentsQuery({
    variables: {
      ...commonVars,
      topCommentsCount: 200, // The top number of comments by score that are used to form the Word Cloud
    },
  })
  return (
    <ResponseHandler {...topResult}>
      {({ insightsComments: topComments }, { loading }) => {
        if (filtersHaveChanged) {
          if (loading) {
            return <Loader />
          }
          setFiltersHaveChanged(false)
        }
        if (!topComments) return <div />
        return (
          <ResponseHandler {...result}>
            {({ insightsComments: comments }) => {
              return (
                <Comments
                  minShowableResults={survey.minShowableResults}
                  isNpsQuestion={question.code === BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP}
                  comments={comments.comments}
                  page={page}
                  onPageChange={setPage}
                  pageSize={pageSize}
                  enhancedComments={enhancedComments}
                  topComments={topComments.comments}
                  totalComments={topComments.totalComments}
                  searchWords={searchWords}
                  onSearchWord={word => {
                    setPage(0)
                    setSearchWords([...searchWords, word.toLowerCase()])
                  }}
                  removeSearchWord={word => {
                    setPage(0)
                    setSearchWords(searchWords.filter(w => w !== word.toLowerCase()))
                  }}
                  clearSearchWords={() => setSearchWords([])}
                  filterBy={filterBy}
                  setFilterBy={newFilterBy => {
                    setPage(0)
                    setFilterBy(newFilterBy)
                  }}
                />
              )
            }}
          </ResponseHandler>
        )
      }}
    </ResponseHandler>
  )
}

export default CustomSurveyComments
