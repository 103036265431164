import React, { useState } from 'react'

import { makeStyles, IconButton, Tooltip, Typography } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import cn from 'classnames'
import { format } from 'date-fns'
import isNil from 'lodash/isNil'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Loader from 'components/Blocks/Loader'
import SearchPopup from 'components/Blocks/Search/SearchPopup'
import Table from 'components/Blocks/Table'
import IndividualResultDetails from 'components/Insights/IndividualResults/IndividualResultDetails'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey, InsightsTabProps } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { NPS_THRESHOLDS } from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import {
  useInsightsIndividualResultsQuery,
  IndividualResultType,
  ParticipantTypeEnum,
  BenchmarkCodeType,
  NpsGroupsEnum,
  useUserUpdateUserAppMessageMutation,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { getNpsAbbreviation } from 'utils'
import { CORE_Q1_RECOMMEND_QUESTION_TEXT } from 'utils/constants'
import { LOCTypeEnum, UserAppMessageEnum } from 'utils/generatedFrontendConstants'

const useStyles = makeStyles(({ spacing, palette }) => ({
  controlsRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& >span': {
      paddingLeft: spacing(),
    },
  },
  successTag: {
    margin: spacing(2),
    backgroundColor: 'rgb(76, 175, 80, 0.2)',
  },
  newResultsTag: {
    fontSize: '1.2rem',
    borderRadius: 3,
    paddingRight: spacing(),
    textAlign: 'center',
    color: palette.common.white,
    backgroundColor: palette.common.secondary,
  },
  npsRow: {
    display: 'flex',
    alignItems: 'center',
  },
  scoreTag: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'uppercase',
    borderRadius: 20,
    paddingLeft: spacing(),
    paddingRight: spacing(),
    paddingTop: 2,
    paddingBottom: 2,
    width: 50,
    alignItems: 'center',
    backgroundColor: palette.common.white,
    border: `2px solid ${palette.common.navy25}`,
  },
  npsTag: {
    '@media print': {
      fontSize: '1.4rem',
    },
  },
  npsPromoter: {
    border: '1px solid rgba(50, 177, 169)',
  },
  npsPassive: {
    border: '1px solid #D0A410',
  },
  npsDetractor: {
    border: '1px solid rgba(219, 98, 95)',
  },
}))

export const getParticipantType = (participantDetails: IndividualResultType) => {
  if (!participantDetails.participantType) {
    return ''
  }
  if (participantDetails.participantType === ParticipantTypeEnum.REPRESENTATIVE) {
    return 'Family/Fiduciary'
  }
  if (!participantDetails.levelOfCare) {
    return 'Resident/Patient'
  }
  if (
    [LOCTypeEnum.SKILLED_NURSING_LT_CARE, LOCTypeEnum.SKILLED_NURSING_REHAB].includes(
      participantDetails.levelOfCare as LOCTypeEnum,
    )
  ) {
    return 'Patient'
  }
  return 'Resident'
}

const NPS_GROUP_TO_LABEL = {
  [NpsGroupsEnum.PROMOTERS]: 'Promoter',
  [NpsGroupsEnum.PASSIVES]: 'Passive',
  [NpsGroupsEnum.DETRACTORS]: 'Detractor',
}
export const getNpsData = (ppt: IndividualResultType) => {
  const npsResponse = ppt.responses.find(
    response => response.questionCode === BenchmarkCodeType.NPS_RECOMMEND,
  )
  const npsAnswer = npsResponse?.answer || null
  let npsGroup
  if (npsAnswer === null) return { npsAnswer, npsGroup }
  const npsScore = Number(npsAnswer)
  if (npsScore >= NPS_THRESHOLDS[NpsGroupsEnum.PROMOTERS][0]) {
    npsGroup = NpsGroupsEnum.PROMOTERS
  } else if (npsScore >= NPS_THRESHOLDS[NpsGroupsEnum.PASSIVES][0]) {
    npsGroup = NpsGroupsEnum.PASSIVES
  } else {
    npsGroup = NpsGroupsEnum.DETRACTORS
  }
  return { npsAnswer: npsScore, npsGroup }
}

export const NpsTag: React.FC<{ npsGroup: NpsGroupsEnum; npsAnswer: number; width?: number }> = ({
  npsGroup,
  npsAnswer,
  width = 120,
}) => {
  const classes = useStyles()
  return (
    <div
      className={cn({
        [classes.npsPromoter]: npsGroup === NpsGroupsEnum.PROMOTERS,
        [classes.npsPassive]: npsGroup === NpsGroupsEnum.PASSIVES,
        [classes.npsDetractor]: npsGroup === NpsGroupsEnum.DETRACTORS,
        [classes.scoreTag]: true,
        [classes.npsTag]: true,
      })}
      style={{ width }}
    >
      {npsAnswer} – {NPS_GROUP_TO_LABEL[npsGroup]}
    </div>
  )
}

export const CoreQScoreTag: React.FC<{
  answer: number | string | null
  includeSkipped?: boolean
}> = ({ answer, includeSkipped }) => {
  let scoreColor
  let score = null
  if (!isNil(answer)) {
    score = Number(answer)
    if (score >= 3) {
      scoreColor = colors.success
    } else if (score >= 2) {
      scoreColor = colors.warning
    } else {
      scoreColor = colors.danger
    }
  }
  const classes = useStyles()
  return (
    <>
      {score !== null ? (
        <div className={classes.scoreTag} style={{ border: `2px solid ${scoreColor}` }}>
          <Typography>{score}</Typography>
        </div>
      ) : (
        <Typography color="textSecondary">{includeSkipped && <i>Skipped</i>}</Typography>
      )}
    </>
  )
}

const ParticipantContentKey = 'participants-participant'

enum TableColumnIdsEnum {
  PARTICIPANT_NAME = 'participant-name',
  TYPE = 'type',
  DATE = 'date',
  LOCATION = 'location',
  SCORE = 'score',
  DETAILS = 'details',
  JOB_TITLE = 'job-title',
}

const getColumns = (productType: SurveyProductTypeEnum, scoreColumnHeader?: string) => {
  let columns: { id: TableColumnIdsEnum; label: string }[] = []
  if (productType === SurveyProductTypeEnum.EMPLOYEE) {
    columns = [
      {
        id: TableColumnIdsEnum.PARTICIPANT_NAME,
        label: 'Participant Name',
      },
      {
        id: TableColumnIdsEnum.DATE,
        label: 'Date',
      },
      {
        id: TableColumnIdsEnum.JOB_TITLE,
        label: 'Job Title',
      },
      {
        id: TableColumnIdsEnum.LOCATION,
        label: 'Location',
      },
      {
        id: TableColumnIdsEnum.SCORE,
        label: 'Score',
      },
      {
        id: TableColumnIdsEnum.DETAILS,
        label: '',
      },
    ]
  } else {
    columns = [
      {
        id: TableColumnIdsEnum.PARTICIPANT_NAME,
        label: 'Participant Name',
      },
      {
        id: TableColumnIdsEnum.TYPE,
        label: 'Type',
      },
      {
        id: TableColumnIdsEnum.DATE,
        label: 'Date',
      },
      {
        id: TableColumnIdsEnum.LOCATION,
        label: 'Location',
      },
      { id: TableColumnIdsEnum.SCORE, label: scoreColumnHeader || '' },
      // Empty column for the button link to results detail view.
      { id: TableColumnIdsEnum.DETAILS, label: '' },
    ]
  }

  if (!scoreColumnHeader) {
    columns = columns.filter(column => column.id !== TableColumnIdsEnum.SCORE)
  }

  return columns
}

const IndividualResultsTab: React.FC<Omit<InsightsTabProps, 'survey'> & {
  survey: InsightsSurvey
}> = ({ filters, startDate, endDate, survey, currentUser }) => {
  const dateFormat = 'LLL dd, yyyy h:mm a'
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [modalParticipantIndex, setModalParticipantIndex] = useState<null | number>(null)
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const pageSize = 20
  const offset = page * pageSize
  const [updateAppMesage] = useUserUpdateUserAppMessageMutation()
  const queryResult = useInsightsIndividualResultsQuery({
    variables: {
      surveyUuid: survey.uuid,
      offset,
      limit: pageSize,
      filters,
      startDate,
      endDate,
      searchQuery,
    },
    notifyOnNetworkStatusChange: true, // Show loading on page change
  })
  let description = 'See how each participant responded to the survey.'
  let scoreColumnHeader: string | undefined
  if (survey.includesNpsQuestion && survey.hasCoreQRecommendResponses) {
    description =
      'See how each participant responded to the survey. *The REC score represents the answer to either the 5-point recommendation question or the NPS question depending on what the participant was asked.'
    scoreColumnHeader = 'REC*'
  } else if (survey.includesNpsQuestion) {
    description =
      'See how each participant responded to the survey. *The NPS score represents the answer to the question “How likely is it that you would recommend [Location Name] to a friend or colleague?”'
    scoreColumnHeader = 'NPS*'
  } else if (survey.hasCoreQRecommendResponses) {
    description = `See how each participant responded to the survey. *The REC score represents the answer to the question “${CORE_Q1_RECOMMEND_QUESTION_TEXT}”.`
    scoreColumnHeader = 'REC*'
  }
  const participantHasViewedResults = (participantUuid: string) => {
    return currentUser.appMessages.some(appMessage => {
      return (
        appMessage.kind === UserAppMessageEnum.VIEW_INDIVIDUAL_RESULTS &&
        appMessage.contentObjects.find(
          co => co.contentType === ParticipantContentKey && co.uuid === participantUuid,
        )
      )
    })
  }
  return (
    <>
      <div className={cn(classes.fullRow, classes.controlsRow)}>
        <SnapshotChartHeader
          title="Individual Results"
          description={description}
          useBottomPadding={false}
        />
        <SearchPopup
          searchQuery={searchQuery}
          handleSearch={(query: string) => setSearchQuery(query)}
          debounceTime={1000}
        />
      </div>
      <ResponseHandler {...queryResult}>
        {({ insightsIndividualResults }) => {
          if (queryResult.loading) return <Loader />
          const updateHasViewedResults = (participantIndex: number) => {
            const pptUuid = insightsIndividualResults[participantIndex].participantUuid
            if (!participantHasViewedResults(pptUuid)) {
              updateAppMesage({
                variables: {
                  kind: UserAppMessageEnum.VIEW_INDIVIDUAL_RESULTS,
                  contentObjects: [
                    {
                      contentType: ParticipantContentKey,
                      uuid: pptUuid,
                    },
                  ],
                },
              })
            }
          }

          const totalResults = insightsIndividualResults.length
            ? insightsIndividualResults[0].totalResults
            : 0

          const rows = insightsIndividualResults.map((ppt, idx) => {
            const hasViewedResults = participantHasViewedResults(ppt.participantUuid)
            const { npsAnswer, npsGroup } = getNpsData(ppt)
            const participantUsedNps = ppt.questions.some(
              q => q.benchmarkCode === BenchmarkCodeType.NPS_RECOMMEND,
            )
            const participantUsedCoreQ = ppt.questions.some(
              q => q.benchmarkCode === BenchmarkCodeType.CORE_Q1_RECOMMEND,
            )

            const rowDataByColumnId: {
              [key in TableColumnIdsEnum]?: { name: string; value: any }
            } = {
              [TableColumnIdsEnum.PARTICIPANT_NAME]: {
                name: 'Participant Name',
                value: (
                  <div className={classes.nameColumn} id={`ppt-name-${idx}`}>
                    <span>{ppt.name || 'N/A'}</span>
                    {!hasViewedResults && <span className={classes.newResultsTag}>NEW</span>}
                  </div>
                ),
              },
              [TableColumnIdsEnum.DATE]: {
                name: 'Date',
                value: format(new Date(ppt.startedTimestamp), dateFormat),
              },
              [TableColumnIdsEnum.LOCATION]: {
                name: 'Location',
                value: ppt.locationName,
              },
              [TableColumnIdsEnum.DETAILS]: {
                name: '',
                value: (
                  <Tooltip title="View Results" placement="top">
                    <IconButton
                      onClick={() => {
                        updateHasViewedResults(idx)
                        setModalParticipantIndex(idx)
                      }}
                      id={`ppt-details-${idx}`}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                ),
              },
            }

            if (survey.productType === SurveyProductTypeEnum.EMPLOYEE) {
              rowDataByColumnId[TableColumnIdsEnum.JOB_TITLE] = {
                name: 'Job Title',
                value: ppt.jobTitle,
              }
            } else {
              rowDataByColumnId[TableColumnIdsEnum.TYPE] = {
                name: 'Type',
                value: getParticipantType(ppt),
              }
            }

            if (participantUsedNps) {
              rowDataByColumnId[TableColumnIdsEnum.SCORE] = {
                name: getNpsAbbreviation(survey.productType),
                value:
                  (npsAnswer || npsAnswer === 0) && npsGroup ? (
                    <div className={classes.npsRow}>
                      <NpsTag npsGroup={npsGroup} npsAnswer={npsAnswer} />
                    </div>
                  ) : (
                    <div />
                  ),
              }
            } else if (participantUsedCoreQ) {
              const coreQResponse = ppt.responses.find(
                r => r.questionCode === BenchmarkCodeType.CORE_Q1_RECOMMEND,
              )?.answer
              rowDataByColumnId[TableColumnIdsEnum.SCORE] = {
                name: 'CoreQ',
                value: <CoreQScoreTag answer={isNil(coreQResponse) ? null : coreQResponse} />,
              }
            } else {
              rowDataByColumnId[TableColumnIdsEnum.SCORE] = {
                name: '',
                value: '',
              }
            }
            return rowDataByColumnId
          })

          const columns = getColumns(survey.productType, scoreColumnHeader)

          return (
            <>
              {modalParticipantIndex !== null && (
                <IndividualResultDetails
                  onClose={() => setModalParticipantIndex(null)}
                  resultDetails={insightsIndividualResults[modalParticipantIndex]}
                  surveyName={survey.name}
                  surveyType={survey.type}
                  productType={survey.productType}
                  resultIndex={modalParticipantIndex}
                  numResults={totalResults}
                  tableIsLoading={queryResult.loading}
                  onPageChange={async direction => {
                    let nextIndex = 0
                    if (direction === 'back') {
                      nextIndex = modalParticipantIndex - 1
                      if (nextIndex < offset) {
                        setPage(page - 1)
                      }
                    } else {
                      nextIndex = modalParticipantIndex + 1
                      if (nextIndex >= offset + pageSize) {
                        await queryResult.fetchMore({
                          variables: { offset: offset + pageSize },
                        })
                        setPage(page + 1)
                      }
                    }
                    updateHasViewedResults(nextIndex)
                    setModalParticipantIndex(nextIndex)
                  }}
                />
              )}
              <div id="individual-results-snap">
                <Table
                  bicolor
                  page={page}
                  pageSize={pageSize}
                  setPage={async newPage => {
                    await queryResult.fetchMore({ variables: { offset: newPage * pageSize } })
                    setPage(newPage)
                  }}
                  columns={columns}
                  totalRows={totalResults}
                  // This makes sure that the cell in every row respects the columns ordering
                  rows={
                    rows.map(rowDataByColumnId =>
                      columns.map(col => rowDataByColumnId[col.id]),
                    ) as {
                      name: string
                      value: any
                    }[][]
                  }
                />
              </div>
            </>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default IndividualResultsTab
