import React, { useState } from 'react'

import { Typography, MenuItem, Grid, makeStyles } from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import FormPanel from 'components/Blocks/Accordions/FormPanel'
import Checkbox from 'components/Blocks/Checkboxes/Checkbox'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import Dropdown from 'components/Blocks/Dropdowns/Dropdown'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import {
  SurveysSurveyQuery,
  useSurveysUpdateEligibleParticipantSurveyConfigsMutation,
} from 'generated/graphql'
import { PARTICIPANT_TYPE_OPTIONS_LABELS } from 'utils/constants'
import { ParticipantTypeOptionsEnum } from 'utils/generatedFrontendConstants'

type Config = SurveysSurveyQuery['survey']['eligibleParticipantSurveyConfigs'][0]
type Props = {
  survey: SurveysSurveyQuery['survey']
}
const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    marginLeft: 0,
    marginTop: 0,
  },
}))

const EligibleParticipantConfig: React.FC<Props> = ({ survey }) => {
  const classes = useStyles()
  const formatConfigs = (configs: Config[]) => {
    // Remove 'typename' property and sort alphabetically by levelOfCare
    return orderBy(
      configs.map(c => ({
        enabled: c.enabled,
        participantTypeOptions: c.participantTypeOptions,
        levelOfCare: c.levelOfCare,
      })),
      'levelOfCare',
    )
  }
  const [configs, setConfigs] = useState(formatConfigs(survey.eligibleParticipantSurveyConfigs))
  const [
    updateConfigs,
    { loading: isUpdatingConfigs },
  ] = useSurveysUpdateEligibleParticipantSurveyConfigsMutation()
  if (!survey.usesSamplePoolDataSource) {
    return <div />
  }
  return (
    <FormPanel
      gutterBottom
      title="Participant Filters"
      helpText="Use this filter to choose the participants you want to receive this survey. This ensures the survey reaches the most appropriate respondents for valuable and relevant feedback. Then we can collect data on the following levels of care from your integration, if you need another level of care, please contact the Activated Insights team."
    >
      <div>
        <Grid container>
          <Grid item sm={6}>
            <Typography>Levels of Care</Typography>
          </Grid>
          <Grid item sm={6} className={classes.row}>
            <TitleWithInfoTooltip
              title="Participant Type"
              variant="body1"
              tooltip="Clients consist of Patients or Residents. Contacts
consist of Family Members or Responsible party"
            />
          </Grid>
          <br />
          <br />
          {configs.map(config => {
            return (
              <Grid container key={config.levelOfCare} className={classes.row}>
                <Grid item sm={6}>
                  <Checkbox
                    checked={config.enabled}
                    label={config.levelOfCare}
                    onChange={() => {
                      setConfigs(
                        formatConfigs([
                          ...configs.filter(c => c.levelOfCare !== config.levelOfCare),
                          { ...config, enabled: !config.enabled },
                        ]),
                      )
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Dropdown
                    disabled={!config.enabled}
                    value={config.participantTypeOptions}
                    onChange={e => {
                      setConfigs(
                        formatConfigs([
                          ...configs.filter(c => c.levelOfCare !== config.levelOfCare),
                          { ...config, participantTypeOptions: String(e.target.value) },
                        ]),
                      )
                    }}
                  >
                    {Object.values(ParticipantTypeOptionsEnum).map(option => {
                      return (
                        <MenuItem key={option} value={option}>
                          {PARTICIPANT_TYPE_OPTIONS_LABELS[option]}
                        </MenuItem>
                      )
                    })}
                  </Dropdown>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </div>
      <SubmitButton
        className={classes.saveButton}
        onClick={() => {
          updateConfigs({
            variables: { surveyUuid: survey.uuid, configs },
          })
        }}
        isSubmitting={isUpdatingConfigs}
      >
        Save
      </SubmitButton>
    </FormPanel>
  )
}

export default EligibleParticipantConfig
