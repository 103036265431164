import React, { useContext } from 'react'

import cn from 'classnames'
import orderBy from 'lodash/orderBy'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import RankedStatementsCard from 'components/Insights/Blocks/RankedStatementsCard'
import SeeMore from 'components/Insights/Blocks/StatementsSeeMoreButton'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import KeyDemographics from 'components/Insights/ResidentSnapshot/KeyDemographicsCard'
import ResponseRateContainer from 'components/Insights/ResponseRate/ResponseRateContainer'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import DimensionsCard from 'components/Insights/Snapshot/DimensionsCard'
import EmployeeExperienceCard from 'components/Insights/Snapshot/EmployeeExperienceCard'
import EmployeeKeyFocusAreasCard from 'components/Insights/Snapshot/EmployeeKeyFocusAreasCard'
import MoveableMiddleCard from 'components/Insights/Snapshot/MoveableMiddleCard'
import OverallIndexCard from 'components/Insights/Snapshot/OverallIndexCard'
import TTDetailedBreakdownCard from 'components/Insights/TimeTrending/TTDetailedBreakdownCard'
import TTDimensionsCard from 'components/Insights/TimeTrending/TTDimensionsCard'
import TTIndexScoreResponseCard from 'components/Insights/TimeTrending/TTIndexScoreResponseCard'
import TTKeyDemographicsCard from 'components/Insights/TimeTrending/TTKeyDemographicsCard'
import TTScatterAndTableCard from 'components/Insights/TimeTrending/TTScatterAndTableCard'
import TTSnapshotStatementsCard from 'components/Insights/TimeTrending/TTSnapshotStatementsCard'
import { getValidTimeTrendingSurveys } from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, StoreContext } from 'config/LocalStorage'
import TTEmployeeExperienceContainer from 'containers/TimeTrending/TTEmployeeExperienceContainer'
import {
  useInsightsSnapshotOverviewQuery,
  BenchmarkCodeType,
  useInsightsDimensionsOverviewQuery,
} from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import {
  RESPONSE_TYPES,
  RANKED_STATEMENTS_LIMIT,
  SKIP_CODES_FOR_INCONSISTENT,
  SKIP_CODES_FOR_POSITIVE,
  URLS,
  ORDER_TYPES,
} from 'utils/constants'

const TrustIndexSnapshotContainer: React.FC<InsightsTabProps> = props => {
  const {
    store: {
      [TimeTrendingChartKey.EMPLOYEE_INDEX_SCORE]: showIndexScoreTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_DETAILED_BREAKDOWN]: showDetailedBreakdownTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_DIMENSIONS]: showDimensionsTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_SNAPSHOT_STATEMENTS]: showStatementsTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_KEY_DEMOGRAPHICS]: showKeyDemographicsTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_CULTURE]: showCultureTimeTrending,
    },
  } = useContext(StoreContext)
  const classes = useInsightsStyles()
  const {
    survey,
    filters,
    solution,
    availableSurveys,
    benchmark,
    currentUser: { organization },
  } = props
  const result = useInsightsSnapshotOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      benchmark,
    },
  })
  const dimensionsResult = useInsightsDimensionsOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
    },
  })
  const hasTimeTrending =
    solution.insightsTimeTrending && getValidTimeTrendingSurveys(availableSurveys).length > 1
  const hasTimeTrendingWithoutPulse =
    solution.insightsTimeTrending && getValidTimeTrendingSurveys(availableSurveys, true).length > 1

  return (
    <>
      <BreadcrumbsItem to={URLS.EMPLOYEE_INSIGHTS.SNAPSHOT}>Snapshot</BreadcrumbsItem>
      <ResponseHandler {...result}>
        {({ insightsOverallIndex, insightsStatements }) => {
          return (
            <ResponseHandler {...dimensionsResult}>
              {({ insightsDimensionsOverview }) => {
                const positiveStatements = insightsStatements.filter(
                  stmt => !SKIP_CODES_FOR_POSITIVE.includes(stmt.code as BenchmarkCodeType),
                )
                const topPositiveStatements = orderBy(
                  positiveStatements,
                  ['positive', 'label'],
                  ORDER_TYPES.DESCENDING,
                ).slice(0, RANKED_STATEMENTS_LIMIT)
                const inconsistentStatements = insightsStatements.filter(
                  stmt => !SKIP_CODES_FOR_INCONSISTENT.includes(stmt.code as BenchmarkCodeType),
                )
                const topInconsistentStatements = orderBy(
                  inconsistentStatements,
                  ['inconsistent', 'label'],
                  ORDER_TYPES.DESCENDING,
                ).slice(0, RANKED_STATEMENTS_LIMIT)

                let overallIndexDescription
                const positiveScore = Math.round(insightsOverallIndex.positive)
                if (positiveScore >= 75) {
                  overallIndexDescription = `Congratulations! This score of ${positiveScore}% qualified you to be Great Place to Work Certified.`
                } else if (positiveScore >= 65) {
                  overallIndexDescription =
                    'Nice work! 7 out of 10 employees think your company is a great place to work.'
                } else {
                  const dimensionsCopy = insightsDimensionsOverview.slice()
                  dimensionsCopy.sort((a, b) => a.positive - b.positive)
                  const [dim1, dim2] = dimensionsCopy.slice(0, 2)
                  overallIndexDescription = `Focus on increasing the sense of ${dim1.label} and ${dim2.label} to improve employee experience.`
                }
                return (
                  <>
                    {showIndexScoreTimeTrending ? (
                      <div className={classes.fullRow} id="tt-index-score-snap">
                        <TTIndexScoreResponseCard {...props} />
                      </div>
                    ) : (
                      <div className={classes.halfRow}>
                        <OverallIndexCard
                          insightsOverallIndex={insightsOverallIndex}
                          title="Trust Index Score"
                          benchmarkName={benchmark.name}
                          extraDescription={overallIndexDescription}
                        />
                        <ResponseRateContainer
                          survey={survey}
                          filterValueUuids={filters}
                          hasTimeTrending={hasTimeTrending}
                        />
                      </div>
                    )}
                    {solution.insightsSnapshots && (
                      <>
                        {showDetailedBreakdownTimeTrending ? (
                          <>
                            <TTDetailedBreakdownCard {...props} />
                            <TTScatterAndTableCard
                              {...props}
                              companyOverallPositive={positiveScore}
                            />
                          </>
                        ) : (
                          <div id="detailed-breakdown-snap" className={classes.fullRow}>
                            <DetailedBreakdownCard
                              {...props}
                              hasTimeTrending={hasTimeTrending}
                              title="Detailed Breakdown"
                              description="Scores varied across your organization - focus on the teams that most need support to increase your overall score."
                              tooltip="This chart shows the Trust Index© score across your organization. It's calculated by taking the % of positive scores across all statements on the Great Place to Work® Trust Model© and shows the % of employees having an overall positive experience."
                              benchmarkPositive={insightsOverallIndex.benchmarkPositive || 0}
                              overallPositiveScore={insightsOverallIndex.positive}
                            />
                          </div>
                        )}
                        {showDimensionsTimeTrending ? (
                          <TTDimensionsCard {...props} />
                        ) : (
                          <div
                            id="dimensions-snap"
                            className={cn(classes.fullRow, classes.pageBreak)}
                          >
                            <DimensionsCard
                              {...props}
                              hasTimeTrending={hasTimeTrendingWithoutPulse}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {showStatementsTimeTrending ? (
                      <TTSnapshotStatementsCard {...props} />
                    ) : (
                      <>
                        <div id="employees-rate-snap" className={classes.fullRow}>
                          <RankedStatementsCard
                            {...props}
                            title="What employees rate HIGH about their experience"
                            tooltipText={`
                    These statements had the most positive responses - employees responded 'Often true' or
                    'Almost always true'`}
                            statements={topPositiveStatements}
                            scoreKey="positive"
                            benchmarkScoreKey="benchmarkPositive"
                            snapId="employees-rate-snap"
                            hasTimeTrending={hasTimeTrending}
                            timeTrendingChartKey={TimeTrendingChartKey.EMPLOYEE_SNAPSHOT_STATEMENTS}
                          />
                          <SeeMore
                            solution={solution}
                            survey={survey}
                            responseType={RESPONSE_TYPES.pos}
                          />
                        </div>

                        <div id="moveable-snap" className={cn(classes.fullRow, classes.pageBreak)}>
                          <MoveableMiddleCard {...props} statements={topInconsistentStatements} />
                          <SeeMore
                            solution={solution}
                            survey={survey}
                            responseType={RESPONSE_TYPES.inc}
                          />
                        </div>
                      </>
                    )}
                    {solution.insightsSnapshots && (
                      <EmployeeKeyFocusAreasCard
                        surveyUuid={survey.uuid}
                        minShowableResults={survey.minShowableResults}
                        surveyProductType={survey.productType}
                        filters={filters}
                        allStatements={positiveStatements}
                        inconsistentStatements={topInconsistentStatements}
                        visibleFilterTypes={props.visibleFilterTypes}
                        overallIndexScore={insightsOverallIndex.positive}
                        hasKeyFocusAreaCarousel={organization.hasKeyFocusAreaCarousel}
                      />
                    )}
                    {solution.insightsKeyDemographics &&
                      (showKeyDemographicsTimeTrending ? (
                        <TTKeyDemographicsCard {...props} />
                      ) : (
                        <KeyDemographics
                          survey={survey}
                          filters={filters}
                          surveyProductType={survey.productType}
                          benchmark={benchmark}
                          hasTimeTrending={hasTimeTrending}
                        />
                      ))}
                    {solution.insightsSnapshots &&
                      (showCultureTimeTrending ? (
                        <TTEmployeeExperienceContainer {...props} />
                      ) : (
                        <div
                          id="employee-experience-snap"
                          className={cn(classes.fullRow, classes.pageBreak)}
                        >
                          <EmployeeExperienceCard
                            {...props}
                            hasTimeTrending={hasTimeTrendingWithoutPulse}
                          />
                        </div>
                      ))}
                  </>
                )
              }}
            </ResponseHandler>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default withErrorHandler(TrustIndexSnapshotContainer)
