import { ComponentType } from 'react'

import CommentsContainer from 'components/Insights/CommentsContainer'
import ComparisonsContainer from 'components/Insights/Comparisons/Comparisons'
import CustomSurveySnapshotContainer from 'components/Insights/CustomSurvey/CustomSurveySnapshotContainer'
import DischargeSnapshot from 'components/Insights/Discharge/DischargeSnapshot'
import HeatmapContainer from 'components/Insights/Heatmap/Heatmap'
import IndividualResultsContainer from 'components/Insights/IndividualResults/IndividualResultsTab'
import { InsightsSurvey, InsightsTabProps } from 'components/Insights/InsightsTypes'
import LifecycleResidentSurveySnapshot from 'components/Insights/ResidentSnapshot/LifecycleResidentSurveySnapshot'
import ResidentEndOfServiceContainer from 'components/Insights/ResidentSnapshot/ResidentEndOfServiceContainer'
import ResidentEngagementOrPulseSnapshot from 'components/Insights/ResidentSnapshot/ResidentEngagementOrPulseSnapshot'
import EmployeePulseSnapshot from 'components/Insights/Snapshot/EmployeePulseSnapshot'
import LifecycleEmployeeSurveySnapshot from 'components/Insights/Snapshot/LifecycleEmployeeSurveySnapshot'
import TrustIndexSnapshotContainer from 'components/Insights/Snapshot/TrustIndexSnapshotContainer'
import StatementsControlsWrapper from 'components/Insights/Statements/StatementsControlsWrapper'
import TakeAction from 'components/Insights/TakeAction/TakeAction'
import Testimonials from 'components/Insights/Testimonials/Testimonials'
import { getTimeframeAndTimeTrendingTabSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { InsightsFilter } from 'config/LocalStorage'
import {
  DataTypeCode,
  InsightsModulesEnum,
  OrganizationResidentSolutionFragment,
  OrganizationSolutionFragment,
  SurveyProductTypeEnum,
  SurveyTypeEnum,
  UserDownloadsEnum,
} from 'generated/graphql'
import { isKeyOf, getNpsAbbreviation } from 'utils'
import { CONTACT_EMAIL, CUSTOM_SURVEY_TYPES, TimeframeDropDownLocation } from 'utils/constants'
import { CertificationTypeEnum } from 'utils/generatedFrontendConstants'
import { getInsightsPage } from 'utils/insightsUtils'
import { Solution, SurveyNode, RequireOnlyOne } from 'utils/types'

type _Download = {
  visible: boolean
  downloadType?: string
  downloadUrl?: string
  enabled: boolean
  disabledTitle?: string
  text: string
}

type Download = RequireOnlyOne<_Download, 'downloadType' | 'downloadUrl'>

type Tab = {
  path: (surveyId: string) => string
  name: string
  url: string
  component: ComponentType<InsightsTabProps>
  showResponseTypeSelect: boolean
  showPrint: boolean
  // Either on the top controls or within the component. Null when the survey type does not have timeframe.
  timeframeDropdownLocation: null | TimeframeDropDownLocation
  // Type of trending specified by the survey type. Null when the tab does not have time-trending features
  timeTrendingType: InsightsTabProps['timeTrendingType']
  timeTrendingIconLocation: null | TimeframeDropDownLocation
  screenshotId?: string
  showControls: boolean
  showSearch: boolean
  disabledTabTooltip?: string
  show: boolean
  downloads: Download[]
}
type CommonArgs = {
  solution: Solution
  surveyType: SurveyTypeEnum
  includesTestimonials: boolean
  hasConfidentialResults: boolean
}

const showTestimonialsTabAsDisabled = (
  tab: InsightsModulesEnum,
  { includesTestimonials }: CommonArgs,
) => tab === InsightsModulesEnum.TESTIMONIALS && !includesTestimonials

export const getDisabledTabTooltip = (tab: InsightsModulesEnum, args: CommonArgs) => {
  if (!shouldShowTabAsDisabled(tab, args)) {
    return ''
  }
  if (showTestimonialsTabAsDisabled(tab, args)) {
    return 'Include the testimonials question on your surveys to see results here.'
  }
  const mapping = {
    [InsightsModulesEnum.SNAPSHOTS]: '',
    [InsightsModulesEnum.STATEMENTS]: 'see results by Statements',
    [InsightsModulesEnum.COMMENTS]: 'see results by Comments',
    [InsightsModulesEnum.COMPARISONS]: 'see results by Comparisons',
    [InsightsModulesEnum.HEATMAP]: 'see Heatmap',
    [InsightsModulesEnum.ACTION_PLANS]: 'Take Action to improve',
    [InsightsModulesEnum.TESTIMONIALS]: 'see Testimonials',
    [InsightsModulesEnum.INDIVIDUAL_RESULTS]: 'see Individual Results',
  }
  return `To learn more about how to ${mapping[tab]}, contact ${CONTACT_EMAIL}.`
}

export const INSIGHTS_MODULE_TO_TAB_NAME = {
  [InsightsModulesEnum.SNAPSHOTS]: 'Snapshot',
  [InsightsModulesEnum.STATEMENTS]: 'Statements',
  [InsightsModulesEnum.COMMENTS]: 'Comments',
  [InsightsModulesEnum.COMPARISONS]: 'Comparison Tool',
  [InsightsModulesEnum.HEATMAP]: 'Heatmap',
  [InsightsModulesEnum.ACTION_PLANS]: 'Take Action',
  [InsightsModulesEnum.TESTIMONIALS]: 'Testimonials',
  [InsightsModulesEnum.INDIVIDUAL_RESULTS]: 'Individual Results',
}

export const insightsModuleEnabledInSolution = (
  insightsModule: InsightsModulesEnum,
  solution?: null | Solution,
) => {
  if (!solution) return false
  const mapping = {
    [InsightsModulesEnum.SNAPSHOTS]: solution.insights || solution.insightsSnapshots,
    [InsightsModulesEnum.INDIVIDUAL_RESULTS]: solution.insights || solution.insightsSnapshots,
    [InsightsModulesEnum.STATEMENTS]: solution.insightsStatements,
    [InsightsModulesEnum.COMMENTS]: solution.insightsComments,
    [InsightsModulesEnum.COMPARISONS]: solution.insightsComparisons,
    [InsightsModulesEnum.HEATMAP]: solution.insightsHeatmap,
    [InsightsModulesEnum.ACTION_PLANS]: solution.actionPlans,
    [InsightsModulesEnum.TESTIMONIALS]:
      isKeyOf('insightsTestimonials', solution) &&
      (solution as OrganizationResidentSolutionFragment).insightsTestimonials,
  }
  return mapping[insightsModule]
}

const shouldShowTabAsDisabled = (tab: InsightsModulesEnum, args: CommonArgs) => {
  return (
    args.solution.showUnavailableTabs &&
    (!insightsModuleEnabledInSolution(tab, args.solution) ||
      showTestimonialsTabAsDisabled(tab, args))
  )
}

const shouldShowTab = (
  tab: InsightsModulesEnum,
  { surveyType, hasConfidentialResults }: CommonArgs,
) => {
  let tabOptions = {
    [SurveyTypeEnum.TI]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      InsightsModulesEnum.HEATMAP,
      InsightsModulesEnum.ACTION_PLANS,
    ],
    [SurveyTypeEnum.PULSE]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
    ],
    [SurveyTypeEnum.CUSTOM]: [InsightsModulesEnum.SNAPSHOTS],
    [SurveyTypeEnum.MONTHLY]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.INDIVIDUAL_RESULTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      // Hide Heatmap until we will have benchmarks for the new set of questions
      // InsightsModulesEnum.HEATMAP,
    ],
    [SurveyTypeEnum.END_OF_EMPLOYMENT]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.INDIVIDUAL_RESULTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      // Hide Heatmap until we will have benchmarks for the new set of questions
      // InsightsModulesEnum.HEATMAP,
    ],
    [SurveyTypeEnum.RESIDENT_ENGAGEMENT]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      InsightsModulesEnum.HEATMAP,
      InsightsModulesEnum.ACTION_PLANS,
      InsightsModulesEnum.TESTIMONIALS,
    ],
    [SurveyTypeEnum.RESIDENT_PULSE]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      InsightsModulesEnum.TESTIMONIALS,
    ],
    [SurveyTypeEnum.RESIDENT_CUSTOM]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.INDIVIDUAL_RESULTS,
      InsightsModulesEnum.TESTIMONIALS,
    ],
    [SurveyTypeEnum.RESIDENT_END_OF_SERVICE]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.INDIVIDUAL_RESULTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      InsightsModulesEnum.TESTIMONIALS,
    ],
    [SurveyTypeEnum.RESIDENT_DISCHARGE]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.INDIVIDUAL_RESULTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      InsightsModulesEnum.TESTIMONIALS,
    ],
    [SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.INDIVIDUAL_RESULTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      InsightsModulesEnum.HEATMAP,
      InsightsModulesEnum.TESTIMONIALS,
    ],
    [SurveyTypeEnum.RESIDENT_START_OF_SERVICE]: [
      InsightsModulesEnum.SNAPSHOTS,
      InsightsModulesEnum.INDIVIDUAL_RESULTS,
      InsightsModulesEnum.STATEMENTS,
      InsightsModulesEnum.COMMENTS,
      InsightsModulesEnum.COMPARISONS,
      InsightsModulesEnum.HEATMAP,
      InsightsModulesEnum.TESTIMONIALS,
    ],
  }[surveyType]
  if (hasConfidentialResults) {
    tabOptions = tabOptions.filter(t => t !== InsightsModulesEnum.INDIVIDUAL_RESULTS)
  }
  return tabOptions.includes(tab)
}

const shouldShowInsightsTab = (
  tab: InsightsModulesEnum,
  availableInsightsModules: InsightsModulesEnum[],
  args: CommonArgs,
) =>
  shouldShowTab(tab, args) &&
  (availableInsightsModules.includes(tab) || shouldShowTabAsDisabled(tab, args))

const getDisabledTitle = (title: string = 'reports') => {
  return `To learn more about downloading ${title}, contact ${CONTACT_EMAIL}`
}

const getInsightsTabs = ({
  survey,
  availableInsightsModules,
  solution,
  insightsUrls,
  filters,
  availableSurveys,
}: {
  survey: InsightsSurvey
  availableInsightsModules: InsightsModulesEnum[]
  solution: Solution
  insightsUrls: { [key: string]: string }
  filters: InsightsFilter[]
  availableSurveys: SurveyNode[]
}): Tab[] => {
  const isCustom = CUSTOM_SURVEY_TYPES.includes(survey.type)
  const isEmployeeTI = survey.type === SurveyTypeEnum.TI
  const isResidentEngagement = survey.type === SurveyTypeEnum.RESIDENT_ENGAGEMENT

  // Certification report is available globally for TI surveys.
  // If solution has location-level certification, user can download with 1 location.
  let locationLimit = 0
  if (
    (solution as OrganizationSolutionFragment).certificationType ===
    CertificationTypeEnum.LOCATION_LEVEL
  ) {
    locationLimit = 1
  }
  const showCertificationDownload =
    isEmployeeTI &&
    filters.filter(fv => fv.dtCode === DataTypeCode.AI_LOCATION).length <= locationLimit

  // Summary Results Report is globally available only (no filters applied)
  const showSummaryResultsDownload = filters.length === 0 && isResidentEngagement

  // engagement download is only enabled if max 1 filter of each type is applied
  const filtersSet = new Set(filters.map(f => f.dtCode))
  const showEngagementDownload = filtersSet.size === filters.length && isEmployeeTI

  const SnapshotComponent: ComponentType<InsightsTabProps> = {
    [SurveyTypeEnum.TI]: TrustIndexSnapshotContainer,
    [SurveyTypeEnum.PULSE]: EmployeePulseSnapshot,
    [SurveyTypeEnum.CUSTOM]: CustomSurveySnapshotContainer,
    [SurveyTypeEnum.MONTHLY]: LifecycleEmployeeSurveySnapshot,
    [SurveyTypeEnum.END_OF_EMPLOYMENT]: LifecycleEmployeeSurveySnapshot,
    [SurveyTypeEnum.RESIDENT_ENGAGEMENT]: ResidentEngagementOrPulseSnapshot,
    [SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY]: LifecycleResidentSurveySnapshot,
    [SurveyTypeEnum.RESIDENT_START_OF_SERVICE]: LifecycleResidentSurveySnapshot,
    [SurveyTypeEnum.RESIDENT_PULSE]: ResidentEngagementOrPulseSnapshot,
    [SurveyTypeEnum.RESIDENT_DISCHARGE]: DischargeSnapshot,
    [SurveyTypeEnum.RESIDENT_END_OF_SERVICE]: ResidentEndOfServiceContainer,
    [SurveyTypeEnum.RESIDENT_CUSTOM]: CustomSurveySnapshotContainer,
  }[survey.type]

  const statementsDownloads = [
    {
      visible: true,
      downloadType: UserDownloadsEnum.STATEMENTS_EXPORT,
      text: 'Download Statements Report',
      enabled: solution.statementsExcelDownload,
      disabledTitle: getDisabledTitle('the Statements Report'),
    },
  ]
  if (isResidentEngagement) {
    const npsAbbr = getNpsAbbreviation(survey.productType)

    statementsDownloads.push({
      visible: survey.includesNpsQuestion,
      downloadType: UserDownloadsEnum.NPS_EXPORT,
      text: `Download ${npsAbbr} Report`,
      enabled:
        isKeyOf('npsDownload', solution) &&
        (solution as OrganizationResidentSolutionFragment).npsDownload,
      disabledTitle: getDisabledTitle(`the ${npsAbbr} Report`),
    })
  }
  const args = {
    solution,
    surveyType: survey.type,
    isResidentEngagement,
    includesTestimonials: survey.includesTestimonials,
    hasConfidentialResults: survey.hasConfidentialResults,
  }

  const downloads: Download[] =
    survey.productType === SurveyProductTypeEnum.EMPLOYEE
      ? [
          {
            visible: showCertificationDownload,
            downloadType: UserDownloadsEnum.CERTIFICATION_REPORT,
            text: 'Download Certification Report',
            enabled:
              // Download is available to those with access to certification hub feature.
              ('certificationType' in solution &&
                solution.certificationType !== CertificationTypeEnum.NONE) ||
              // Before certification hub is released, default to the old solution type.
              ('certificationReport' in solution && solution.certificationReport),
            disabledTitle: getDisabledTitle(),
          },
          {
            visible: showEngagementDownload,
            downloadType: UserDownloadsEnum.ENGAGEMENT_REPORT,
            text: 'Download Engagement Report',
            enabled: ('engagementReport' in solution && solution.engagementReport) || false,
            disabledTitle: getDisabledTitle(),
          },
        ]
      : [
          {
            visible: showSummaryResultsDownload,
            downloadType: UserDownloadsEnum.US_NEWS_SUMMARY_RESULTS,
            text: 'Download Result Summary Report',
            enabled:
              isKeyOf('resultsSummaryReportDownload', solution) &&
              (solution as OrganizationResidentSolutionFragment).resultsSummaryReportDownload,
            disabledTitle: getDisabledTitle(),
          },
        ]

  if (isCustom) {
    downloads.push({
      visible: true,
      downloadType:
        survey.type === SurveyTypeEnum.CUSTOM
          ? UserDownloadsEnum.CUSTOM_SURVEY
          : UserDownloadsEnum.RESIDENT_CUSTOM_SURVEY,
      text: 'Download to Excel',
      enabled: true,
      disabledTitle: '',
    })
  }
  if (survey.insightsActionPlanPdfUrl) {
    downloads.push({
      visible: true,
      text: 'Action Plan PDF',
      enabled: true,
      disabledTitle: '',
      downloadUrl: survey.insightsActionPlanPdfUrl,
    })
  }

  const tabs: Tab[] = [
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.SNAPSHOTS, survey.productType),
      name: 'Snapshot',
      url: insightsUrls.SNAPSHOT,
      component: SnapshotComponent,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.SNAPSHOTS,
        survey,
        solution,
        availableSurveys,
      ),
      showResponseTypeSelect: false,
      showPrint: true,
      showControls: true,
      showSearch: false,
      show: shouldShowInsightsTab(InsightsModulesEnum.SNAPSHOTS, availableInsightsModules, args),
      downloads,
    },
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.INDIVIDUAL_RESULTS, survey.productType),
      name: 'Individual Results',
      url: insightsUrls.INDIVIDUAL_RESULTS,
      component: IndividualResultsContainer,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.INDIVIDUAL_RESULTS,
        survey,
        solution,
        availableSurveys,
      ),
      showResponseTypeSelect: true,
      showPrint: true,
      screenshotId: 'individual-results-snap',
      showControls: true,
      showSearch: false,
      show: shouldShowInsightsTab(
        InsightsModulesEnum.INDIVIDUAL_RESULTS,
        availableInsightsModules,
        args,
      ),
      downloads: [
        {
          visible: true,
          downloadType: UserDownloadsEnum.INDIVIDUAL_RESULTS_EXPORT,
          text: 'Download Individual Results (Excel)',
          enabled: true,
        },
      ],
    },
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.STATEMENTS, survey.productType),
      name: 'Statements',
      url: insightsUrls.STATEMENTS,
      component: StatementsControlsWrapper,
      showResponseTypeSelect: true,
      showPrint: true,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.STATEMENTS,
        survey,
        solution,
        availableSurveys,
      ),
      showControls: true,
      showSearch: true,
      show: shouldShowInsightsTab(InsightsModulesEnum.STATEMENTS, availableInsightsModules, args),
      disabledTabTooltip: getDisabledTabTooltip(InsightsModulesEnum.STATEMENTS, args),
      downloads: statementsDownloads,
    },
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.COMMENTS, survey.productType),
      name: 'Comments',
      url: insightsUrls.COMMENTS,
      component: CommentsContainer,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.COMMENTS,
        survey,
        solution,
        availableSurveys,
      ),
      showResponseTypeSelect: false,
      showPrint: true,
      showControls: true,
      showSearch: true,
      show: shouldShowInsightsTab(InsightsModulesEnum.COMMENTS, availableInsightsModules, args),
      disabledTabTooltip: getDisabledTabTooltip(InsightsModulesEnum.COMMENTS, args),
      downloads: [
        {
          visible: true,
          downloadType: UserDownloadsEnum.COMMENTS_EXPORT,
          text: 'Download to Excel',
          enabled: solution.commentsExcelDownload,
          disabledTitle: getDisabledTitle('the Comments Report'),
        },
      ],
    },
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.COMPARISONS, survey.productType),
      name: 'Comparisons',
      url: insightsUrls.COMPARISONS,
      component: ComparisonsContainer,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.COMPARISONS,
        survey,
        solution,
        availableSurveys,
      ),
      showResponseTypeSelect: false,
      showPrint: true,
      showControls: true,
      showSearch: false,
      show: shouldShowInsightsTab(InsightsModulesEnum.COMPARISONS, availableInsightsModules, args),
      disabledTabTooltip: getDisabledTabTooltip(InsightsModulesEnum.COMPARISONS, args),
      downloads: [],
    },
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.HEATMAP, survey.productType),
      name: 'Heatmap',
      url: insightsUrls.HEATMAP,
      component: HeatmapContainer,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.HEATMAP,
        survey,
        solution,
        availableSurveys,
      ),
      showResponseTypeSelect: false,
      showPrint: true,
      showControls: true,
      showSearch: false,
      show: shouldShowInsightsTab(InsightsModulesEnum.HEATMAP, availableInsightsModules, args),
      disabledTabTooltip: getDisabledTabTooltip(InsightsModulesEnum.HEATMAP, args),
      downloads: [],
    },
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.TESTIMONIALS, survey.productType),
      name: 'Testimonials',
      url: insightsUrls.TESTIMONIALS,
      component: Testimonials,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.TESTIMONIALS,
        survey,
        solution,
        availableSurveys,
      ),
      showResponseTypeSelect: false,
      showPrint: true,
      showControls: true,
      showSearch: false,
      show:
        isKeyOf('insightsTestimonials', solution) &&
        shouldShowInsightsTab(InsightsModulesEnum.TESTIMONIALS, availableInsightsModules, args),
      disabledTabTooltip: getDisabledTabTooltip(InsightsModulesEnum.TESTIMONIALS, args),
      downloads: [
        {
          visible: true,
          downloadType: UserDownloadsEnum.TESTIMONIALS_EXPORT,
          text: 'Download to Excel',
          enabled: true,
          disabledTitle: '',
        },
      ],
    },
    {
      path: (surveyId: string) =>
        getInsightsPage(surveyId, InsightsModulesEnum.ACTION_PLANS, survey.productType),
      name: 'Take Action',
      url: insightsUrls.TAKE_ACTION,
      component: TakeAction,
      ...getTimeframeAndTimeTrendingTabSettings(
        InsightsModulesEnum.ACTION_PLANS,
        survey,
        solution,
        availableSurveys,
      ),
      showResponseTypeSelect: false,
      showPrint: false,
      showControls: false,
      showSearch: false,
      show: shouldShowInsightsTab(InsightsModulesEnum.ACTION_PLANS, availableInsightsModules, args),
      disabledTabTooltip: getDisabledTabTooltip(InsightsModulesEnum.ACTION_PLANS, args),
      downloads: [],
    },
  ]
  return tabs.filter(t => t.show)
}

export default getInsightsTabs
