import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  IconButton,
  Tooltip,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'

import PrintButton from 'components/Blocks/CustomButtons/PrintButton'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import FilterControls from 'components/Blocks/Filters/FilterControls'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import SearchPopup from 'components/Blocks/Search/SearchPopup'
import TimeTrendingIcon from 'components/Insights/Blocks/TimeTrendingIcon'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { gaEvent } from 'config/ga'
import {
  InsightsDownloadDocument,
  CurrentUserDocument,
  DataTypeCode,
  UserDownloadsEnum,
  useInsightsResponseRateQuery,
} from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import emitter from 'shared/authenticated/emitter'
import { runDownloadQuery, getVisibleFilterTypes } from 'utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  shares: {
    display: 'flex',
    '& svg': {
      color: palette.common.navy65,
    },
  },
  shareIcon: {
    color: palette.common.navy65,
  },
  searchWrapper: {
    marginLeft: 0,
    position: 'relative',
    '& svg': {
      color: palette.common.navy65,
    },
  },
  searchPaperWrapper: {
    height: 50,
    padding: `0 ${spacing(2)}px`,
    position: 'absolute',
    bottom: -45,
    right: 0,
    zIndex: 3,
  },
}))

const InsightsControls = ({
  benchmark,
  toggleFilter,
  removeFilter,
  clearFilters,
  selectedFilters,
  survey,
  startDate,
  endDate,
  showPrint,
  screenshotId,
  showSearch,
  downloads,
  searchQuery,
  handleStatementSearch,
  showTimeTrendingIcon,
}) => {
  const client = useApolloClient()
  const { currentUser } = client.readQuery({ query: CurrentUserDocument })
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const menuOpen = Boolean(menuAnchorEl)
  const visibleDownloads = downloads.filter(d => d.visible)

  const result = useInsightsResponseRateQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters: selectedFilters.map(f => f.valueUuid),
      byClient: false,
    },
  })

  const handleDownload = async (apolloClient, downloadType) => {
    const filters = selectedFilters.map(f => f.valueUuid)
    const variables = {
      surveyUuid: survey.uuid,
      benchmark,
      filters,
      startDate,
      endDate,
      downloadType,
    }
    if (downloadType === UserDownloadsEnum.CERTIFICATION_REPORT) {
      if (!currentUser.isAdmin) {
        // Admins get cert report for whole survey
        emitter.emit('WARN', 'Only admins can download the Certification Report.')
        return
      }
      variables.locationUuid = selectedFilters.find(
        ft => ft.dtCode === DataTypeCode.AI_LOCATION,
      )?.valueUuid
    }
    gaEvent({
      action: `download-${downloadType}`,
      category: 'Insights',
    })
    runDownloadQuery(() =>
      apolloClient.query({
        query: InsightsDownloadDocument,
        variables,
        fetchPolicy: 'network-only',
      }),
    )
  }
  return (
    <>
      <FilterControls
        filters={getVisibleFilterTypes(currentUser.filters, survey.filterTypeUuids)}
        selectedFilters={selectedFilters}
        surveyUuids={[survey.uuid]}
        surveyProductTypes={[survey.productType]}
        toggleFilter={toggleFilter}
        removeFilter={removeFilter}
        clearFilters={clearFilters}
        fetchPolicy="cache-first"
      >
        <div className={classes.shares}>
          {showTimeTrendingIcon && <TimeTrendingIcon surveyUuid={survey.uuid} />}
          {showSearch && (
            <div className={classes.searchWrapper}>
              <SearchPopup
                searchQuery={searchQuery}
                handleSearch={handleStatementSearch}
                showButtonText={false}
              />
            </div>
          )}
          {showPrint && <PrintButton />}
          {screenshotId && <ScreenshotButton snapId={screenshotId} />}
          {visibleDownloads.length > 0 && (
            <>
              <IconButton
                id="more-button"
                aria-label="More"
                aria-owns={menuOpen ? 'long-menu' : null}
                aria-haspopup="true"
                onClick={e => setMenuAnchorEl(e.currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClick={() => setMenuAnchorEl(null)}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                  },
                }}
              >
                {/** The downloads need to check whether responses with filters are <5, so only
                 * render the menu options when the button is opened. In most cases, the
                 * responses query will be cached by the time the user opens */}
                {menuAnchorEl && (
                  <ResponseHandler {...result}>
                    {({
                      survey: {
                        insightsSurvey: { responseRate },
                      },
                    }) => {
                      const lessThanMinShowableResults =
                        responseRate.finished < survey.minShowableResults
                      return (
                        <>
                          {visibleDownloads.map(
                            ({ downloadType, enabled, disabledTitle, text, downloadUrl }) => (
                              <div key={downloadType}>
                                <Tooltip title={!enabled && disabledTitle ? disabledTitle : ''}>
                                  <span>
                                    <MenuItem
                                      disabled={lessThanMinShowableResults || !enabled}
                                      onClick={() => {
                                        setMenuAnchorEl(null)
                                        if (downloadUrl) {
                                          window.open(downloadUrl, '_blank')
                                        } else {
                                          handleDownload(client, downloadType)
                                        }
                                      }}
                                    >
                                      <ListItemIcon>
                                        <CloudDownloadIcon />
                                      </ListItemIcon>
                                      <ListItemText style={{ paddingLeft: 0 }}>{text}</ListItemText>
                                    </MenuItem>
                                  </span>
                                </Tooltip>
                              </div>
                            ),
                          )}
                        </>
                      )
                    }}
                  </ResponseHandler>
                )}
              </Menu>
            </>
          )}
        </div>
      </FilterControls>
    </>
  )
}

InsightsControls.defaultProps = {
  benchmark: null,
  startDate: null,
  endDate: null,
}

InsightsControls.propTypes = {
  selectedFilters: PropTypes.array.isRequired,
  survey: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  toggleFilter: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  clearFilters: PropTypes.func.isRequired,
  handleStatementSearch: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  showPrint: PropTypes.bool.isRequired,
  showTimeTrendingIcon: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool.isRequired,
  benchmark: PropTypes.object,
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      visible: PropTypes.bool.isRequired,
      enabled: PropTypes.bool.isRequired,
      downloadType: PropTypes.string,
      text: PropTypes.string.isRequired,
      downloadUrl: PropTypes.string,
    }),
  ).isRequired,
}

export default withErrorHandler(InsightsControls)
