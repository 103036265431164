import React, { useContext, useEffect, useRef } from 'react'

import { ApolloError } from '@apollo/client'
import { useMachine } from '@xstate/react'

import { getStartAndEndDateFromTimeframe } from 'components/Insights/Blocks/TimeFrameFilters'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { StoreContext, TimeTrendingSurveysKey } from 'config/LocalStorage'
import { PublicConstantsQuery } from 'generated/graphql'
import { PULSE_SURVEY_TYPES } from 'utils/constants'
import { createDownloadMachine } from 'utils/stateMachines'
import { SurveyNode } from 'utils/types'

// A custom useEffect hook that only triggers on updates, not on initial mount
export const useUpdateEffect = <Dependencies, _>(
  effect: () => void,
  dependencies: Dependencies[] = [],
) => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effect()
    }
    // eslint-disable-next-line
  }, dependencies)
}

// Used for debugging to help determine which props are causing a rerender.
export const useTraceUpdate = <Props extends { [key: string]: any }, _>(props: Props) => {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: { [key: string]: any }, [k, v]) => {
      if (prev.current[k] !== v) {
        // eslint-disable-next-line
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps)
    }
    prev.current = props
  })
}

// Determines whether a pulse survey is included in the list of selected surveys for a time trending chart.
export const usePulseInTimeTrending = (
  availableSurveys: SurveyNode[],
  surveysKey: TimeTrendingSurveysKey,
) => {
  const {
    store: { [surveysKey]: surveyIds },
  } = useContext(StoreContext)
  return availableSurveys.some(
    s => surveyIds.includes(s.uuid) && PULSE_SURVEY_TYPES.includes(s.type),
  )
}

export const useTimeframeSettings = (survey: InsightsSurvey) => {
  const {
    store: { timeframeSettingsBySurvey },
  } = useContext(StoreContext)
  const { startDate, endDate } = getStartAndEndDateFromTimeframe(
    timeframeSettingsBySurvey[survey.uuid],
    survey.startDate,
  )
  return { startDate, endDate }
}

// Context is declared here rather than in App in order to avoid import issues during tests.
export const PublicConstantsContext = React.createContext<PublicConstantsQuery['publicConstants']>(
  // Casting here since we need to create a constant value for the context before we have access
  // to the PublicConstants response. We know, however, that all child components will have access
  // to the non-null value of the PublicConstants.
  ({} as any) as PublicConstantsQuery['publicConstants'],
)

export const usePublicConstants = () => {
  return useContext(PublicConstantsContext)
}

// State machine to handle download states allowing developer to observe
// when download is idle, loading, etc.
// Use send('FETCH_DOWNLOAD') to start download.
export const useDownloadMachine = (
  downloadFn: () => void,
  success: boolean,
  error?: ApolloError,
) => {
  const [state, send] = useMachine(createDownloadMachine(downloadFn))
  // When data is updated, send an event with the result.
  useEffect(() => {
    if (success) {
      send('SUCCESS')
    }
  }, [success, send])
  useEffect(() => {
    if (error) {
      console.error(error)
      send('ERROR')
    }
  }, [error, send])
  return { state, send }
}
