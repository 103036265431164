import React from 'react'

import { makeStyles } from '@material-ui/core'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import NpsBreakdownCard from 'components/Insights/Blocks/NPS/NpsBreakdownCard'
import NpsRecommendCard from 'components/Insights/Blocks/NPS/NpsRecommendCard'
import ReasonsForLeavingCard from 'components/Insights/Blocks/ReasonsForLeavingCard'
import OverallRecommendCard from 'components/Insights/Discharge/OverallRecommendCard'
import SummaryCard from 'components/Insights/Discharge/SummaryCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import KeyDemographics from 'components/Insights/ResidentSnapshot/KeyDemographicsCard'
import ResponseRateContainer from 'components/Insights/ResponseRate/ResponseRateContainer'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import TimeframeCompletionRateCard from 'components/Insights/TimeframeTimeTrending/TimeframeCompletionRateCard'
import TimeframeDetailedBreakdownCard from 'components/Insights/TimeframeTimeTrending/TimeframeDetailedBreakdownCard'
import TimeframeKeyDemographicsCard from 'components/Insights/TimeframeTimeTrending/TimeframeKeyDemographicsCard'
import TimeframeReasonsForLeavingCard from 'components/Insights/TimeframeTimeTrending/TimeframeReasonsForLeavingCard'
import TimeframeRecommendationScoreCard from 'components/Insights/TimeframeTimeTrending/TimeframeRecommendationScoreCard'
import { useTimeTrendingTimeframeSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { BenchmarkCodeType, useInsightsResidentEndOfServiceOverviewQuery } from 'generated/graphql'
import { CORE_Q1_RECOMMEND_QUESTION_TEXT } from 'utils/constants'

const useStyles = makeStyles(() => ({
  commentsLink: {
    '& >p': {
      display: 'inline-block',
    },
  },
}))

const ResidentEndOfServiceContainer: React.FC<InsightsTabProps & {
  showCommentsLink?: boolean
}> = props => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const { survey, filters, benchmark, startDate, endDate, showCommentsLink = true } = props
  const { timeTrendingEnabled, timeRanges } = useTimeTrendingTimeframeSettings(survey)

  const result = useInsightsResidentEndOfServiceOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      startDate,
      endDate,
      statementCodes: [BenchmarkCodeType.CORE_Q1_RECOMMEND],
    },
  })

  return (
    <ResponseHandler {...result}>
      {({ recommendStatementData }) => {
        // End of Service surveys can ask one of Core_Q1_recommend, NPS question, or both.
        // We need to handle when either are missing.
        const recommendStatement = recommendStatementData.length ? recommendStatementData[0] : null
        if (timeTrendingEnabled && timeRanges) {
          const timeTrendingProps = { survey, filters, startDate, endDate, benchmark, timeRanges }
          return (
            <>
              <div className={classes.fullRow}>
                <TimeframeCompletionRateCard {...timeTrendingProps} />
              </div>
              {recommendStatement && (
                <>
                  <div className={classes.fullRow}>
                    <TimeframeRecommendationScoreCard {...timeTrendingProps} />
                  </div>
                  <TimeframeDetailedBreakdownCard
                    {...props}
                    timeRanges={timeRanges}
                    scoreName="Recommendation Score"
                  />
                </>
              )}
              <TimeframeKeyDemographicsCard {...timeTrendingProps} />
              <TimeframeReasonsForLeavingCard {...timeTrendingProps} />
            </>
          )
        }
        return (
          <>
            <div className={classes.halfRow}>
              <SummaryCard survey={survey} />
              <ResponseRateContainer survey={survey} filterValueUuids={filters} showTitle={false} />
            </div>
            {recommendStatement && (
              <>
                <div className={classes.fullRow}>
                  {recommendStatement.count > 0 ? (
                    <OverallRecommendCard statement={recommendStatement} {...props} />
                  ) : (
                    <EmptyState
                      title=""
                      description="No responses received during this period from your selected filters"
                      Icon={ChartUpwardIcon}
                    />
                  )}
                </div>
                <DetailedBreakdownCard
                  {...props}
                  title="Overall Recommendation Score By"
                  useFilterNameSuffixInTitle
                  description={
                    <>
                      We are using the statement, “{CORE_Q1_RECOMMEND_QUESTION_TEXT}” as the key
                      indicator for how participants feel. The percent represents the Overall
                      Recommendation Score for each location.
                      <br />
                    </>
                  }
                  tooltip={`The Recommendation score is the percentage of satisfied customers, who gave a positive response to the question: “${recommendStatement.label}”`}
                  hasTimeTrending={false}
                  overallPositiveScore={recommendStatement.positive}
                  benchmarkPositive={recommendStatement.benchmarkPositive || 0}
                  statementCode={BenchmarkCodeType.CORE_Q1_RECOMMEND}
                  startDate={startDate}
                  endDate={endDate}
                  breakdownModalScoreTitle="Recommendation Score"
                />
              </>
            )}
            {survey.includesNpsQuestion && (
              <div className={classes.fullRow}>
                <NpsRecommendCard
                  survey={survey}
                  productType={survey.productType}
                  filters={filters}
                  showCommentsLink={showCommentsLink}
                />
              </div>
            )}
            {survey.includesNpsQuestion && (
              <div className={classes.fullRow}>
                <NpsBreakdownCard
                  survey={survey}
                  filters={filters}
                  filterTypes={props.visibleFilterTypes}
                />
              </div>
            )}
            <div className={classes.fullRow}>
              <ReasonsForLeavingCard survey={survey} filters={filters} benchmark={benchmark} />
            </div>
            <KeyDemographics
              survey={survey}
              filters={filters}
              benchmark={benchmark}
              surveyProductType={survey.productType}
              hasTimeTrending={false}
            />
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default ResidentEndOfServiceContainer
