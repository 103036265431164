import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'
import orderBy from 'lodash/orderBy'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import NpsBreakdownCard from 'components/Insights/Blocks/NPS/NpsBreakdownCard'
import NpsRecommendCard from 'components/Insights/Blocks/NPS/NpsRecommendCard'
import RankedStatementsCard from 'components/Insights/Blocks/RankedStatementsCard'
import ReasonsForLeavingCard from 'components/Insights/Blocks/ReasonsForLeavingCard'
import SeeMore from 'components/Insights/Blocks/StatementsSeeMoreButton'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import KeyDemographics from 'components/Insights/ResidentSnapshot/KeyDemographicsCard'
import ResponseRateContainer from 'components/Insights/ResponseRate/ResponseRateContainer'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import EmployeeKeyFocusAreasCard from 'components/Insights/Snapshot/EmployeeKeyFocusAreasCard'
import MoveableMiddleCard from 'components/Insights/Snapshot/MoveableMiddleCard'
import OverallIndexCard from 'components/Insights/Snapshot/OverallIndexCard'
import { InsightsModulesEnum, useInsightsSnapshotOverviewQuery } from 'generated/graphql'
import {
  RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS,
  ORDER_TYPES,
  RESPONSE_TYPES,
} from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    margin: spacing(4),
    width: 300,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  tooltip: {
    padding: spacing(2),
  },
}))

const LifecycleEmployeeSurveySnapshot: React.FC<InsightsTabProps> = props => {
  // Most browsers have a limit of 6 concurrent connections. In order to provide the optimal user experience
  // we want to quickly load data for the first set of charts so the user has something to look at while
  // we load the other charts. The remaining charts, we load in groups, so that we stay under the concurrency limit.
  const {
    survey,
    filters,
    startDate,
    endDate,
    benchmark,
    solution,
    currentUser: { organization },
  } = props
  const hasAccessToExtendedSnapshot = solution.insightsSnapshots
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const result = useInsightsSnapshotOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      startDate,
      endDate,
      benchmark,
    },
  })

  return (
    <>
      <BreadcrumbsItem
        to={getInsightsPage(survey.uuid, InsightsModulesEnum.SNAPSHOTS, survey.productType)}
      >
        Snapshot
      </BreadcrumbsItem>
      <ResponseHandler {...result}>
        {({ insightsOverallIndex, insightsStatements }) => {
          const overallPositive = Math.round(insightsOverallIndex.positive)
          const benchmarkPositive = Math.round(insightsOverallIndex.benchmarkPositive || 0)
          const positiveStatements = orderBy(
            insightsStatements,
            ['positive', 'label'],
            ORDER_TYPES.DESCENDING,
          )
          const negativeStatements = orderBy(
            insightsStatements,
            ['negative', 'label'],
            ORDER_TYPES.DESCENDING,
          )
          const topInconsistentStatements = orderBy(
            insightsStatements,
            ['inconsistent', 'label'],
            ORDER_TYPES.DESCENDING,
          ).slice(0, RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS)

          if (insightsStatements.length === 0) {
            return <ResponseRateContainer survey={survey} filterValueUuids={filters} />
          }

          return (
            <>
              <div className={classes.halfRow}>
                <OverallIndexCard
                  title="Engagement Score"
                  benchmarkName={benchmark.name}
                  insightsOverallIndex={insightsOverallIndex}
                />
                <ResponseRateContainer survey={survey} filterValueUuids={filters} />
              </div>
              {hasAccessToExtendedSnapshot && (
                <>
                  <DetailedBreakdownCard
                    {...props}
                    hasTimeTrending={false}
                    title="Detailed Breakdown"
                    description="Scores varied across your organization - focus on the teams that most need support to increase your overall score."
                    tooltip="This chart shows customer engagement scores across your organization. The engagement score is the positive score across all core statements and services for the filter that you're looking at."
                    benchmarkPositive={benchmarkPositive}
                    overallPositiveScore={overallPositive}
                  />
                </>
              )}
              {insightsStatements.length === 1 ? (
                <EmptyState
                  title="Oh Snap!"
                  description="You only have 1 rated statement, please view scores in the Statements tab"
                  Icon={ChartUpwardIcon}
                />
              ) : (
                <>
                  <div className={classes.fullRow} id="top-statements-snap">
                    <RankedStatementsCard
                      {...props}
                      scoreKey="positive"
                      benchmarkScoreKey="benchmarkPositive"
                      statements={positiveStatements.slice(
                        0,
                        RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS,
                      )}
                      title="Strengths"
                      description="There is a lot to celebrate! Make sure your team knows these statements are their greatest strengths."
                      tooltipText={
                        <div className={classes.tooltip}>
                          <Typography>Statement Scores</Typography>
                          <Typography color="textSecondary">
                            The statement questions use a 5-point scale: Positive responses (4-5),
                            Inconsistent responses (3), and Negative responses (1-2) This is an
                            aggregate score showing how many respondents answered positively on each
                            statement question.
                          </Typography>
                          <br />
                          <Typography>How is the Statement Score calculated?</Typography>
                          <Typography color="textSecondary">
                            To calculate the statement score we take the number of positive
                            responses, and divide them by the total number of responses.
                          </Typography>
                        </div>
                      }
                      snapId="top-statements-snap"
                    />
                  </div>
                  <div className={classes.fullRow} id="bottom-statements-snap">
                    <RankedStatementsCard
                      {...props}
                      statements={negativeStatements.slice(
                        0,
                        RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS,
                      )}
                      scoreKey="negative"
                      benchmarkScoreKey="benchmarkNegative"
                      title="Opportunities"
                      description="These are the statements with the highest percentage of negative responses. Improving these statement scores will help to enhance the patient experience overall."
                      tooltipText={
                        <div className={classes.tooltip}>
                          <Typography>Statement Scores</Typography>
                          <Typography color="textSecondary">
                            These are your lowest scoring statements. The statements are ranked in
                            this section by highest negative responses, meaning patients that
                            answered with a negative score of 1 or 2. These statements represent
                            your greatest opportunities for growth and improvement.
                          </Typography>
                          <br />
                          <Typography>How is the Statement Score calculated?</Typography>
                          <Typography color="textSecondary">
                            To calculate the statement score we take the number of positive
                            responses, and divide them by the total number of responses.
                          </Typography>
                        </div>
                      }
                      snapId="bottom-statements-snap"
                    />
                  </div>
                </>
              )}
              <div id="moveable-snap" className={cn(classes.fullRow, classes.pageBreak)}>
                <MoveableMiddleCard {...props} statements={topInconsistentStatements} />
                <SeeMore solution={solution} survey={survey} responseType={RESPONSE_TYPES.inc} />
              </div>
              {solution.insightsSnapshots && (
                <EmployeeKeyFocusAreasCard
                  surveyUuid={survey.uuid}
                  minShowableResults={survey.minShowableResults}
                  surveyProductType={survey.productType}
                  filters={filters}
                  startDate={startDate}
                  endDate={endDate}
                  allStatements={positiveStatements}
                  inconsistentStatements={topInconsistentStatements}
                  visibleFilterTypes={props.visibleFilterTypes}
                  overallIndexScore={insightsOverallIndex.positive}
                  hasKeyFocusAreaCarousel={organization.hasKeyFocusAreaCarousel}
                />
              )}
              {survey.includesLeftReasonQuestion && (
                <div className={classes.fullRow}>
                  <ReasonsForLeavingCard survey={survey} benchmark={benchmark} filters={filters} />
                </div>
              )}
              <KeyDemographics
                survey={survey}
                filters={filters}
                surveyProductType={survey.productType}
                benchmark={benchmark}
                hasTimeTrending={false}
              />
              {survey.includesNpsQuestion && (
                <>
                  <NpsRecommendCard
                    survey={survey}
                    productType={survey.productType}
                    filters={filters}
                    useFormPanel
                    showCommentsLink={hasAccessToExtendedSnapshot}
                  />
                  {hasAccessToExtendedSnapshot && (
                    <NpsBreakdownCard
                      isSnapshotNps
                      survey={survey}
                      filterTypes={props.visibleFilterTypes}
                      filters={filters}
                    />
                  )}
                </>
              )}
            </>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default LifecycleEmployeeSurveySnapshot
