import React, { useContext } from 'react'

import Typography from '@material-ui/core/Typography'
import { NavLink } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import KeyDemographics from 'components/Insights/ResidentSnapshot/KeyDemographicsCard'
import ResponseRateContainer from 'components/Insights/ResponseRate/ResponseRateContainer'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import StatementsContainer from 'components/Insights/Statements/StatementsContainer'
import TTDetailedBreakdownCard from 'components/Insights/TimeTrending/TTDetailedBreakdownCard'
import TTIndexScoreResponseCard from 'components/Insights/TimeTrending/TTIndexScoreResponseCard'
import TTKeyDemographicsCard from 'components/Insights/TimeTrending/TTKeyDemographicsCard'
import TTScatterAndTableCard from 'components/Insights/TimeTrending/TTScatterAndTableCard'
import TTSnapshotStatementsCard from 'components/Insights/TimeTrending/TTSnapshotStatementsCard'
import { TimeTrendingChartKey, StoreContext, TimeTrendingSurveysKey } from 'config/LocalStorage'
import KeyStatementContainer from 'containers/Survey/Wizard/Steps/Summary/KeyStatementContainer'
import {
  useInsightsStatementsQuery,
  InsightsModulesEnum,
  useInsightsSnapshotOverviewQuery,
} from 'generated/graphql'
import { RESPONSE_TYPES, STATEMENT_CODES } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const EmployeePulseSnapshot: React.FC<InsightsTabProps> = props => {
  const {
    survey,
    responseRate,
    filters,
    solution,
    benchmark,
    availableSurveys,
    timeTrendingType,
  } = props

  const {
    store: {
      [TimeTrendingChartKey.EMPLOYEE_INDEX_SCORE]: showIndexScoreTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_DETAILED_BREAKDOWN]: showDetailedBreakdownTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_SNAPSHOT_STATEMENTS]: showStatementsTimeTrending,
      [TimeTrendingChartKey.EMPLOYEE_KEY_DEMOGRAPHICS]: showKeyDemographicsTimeTrending,
    },
  } = useContext(StoreContext)
  const classes = useInsightsStyles()
  const keyStatementCode = STATEMENT_CODES.EMPLOYEE_PULSE_KEY_STATEMENT
  const keyStatementResult = useInsightsStatementsQuery({
    variables: {
      // We know this exists but can't conditionally render a hook
      surveyUuid: String(survey.associatedSurvey?.uuid),
      limit: 1,
      filters,
      benchmark,
      statementCodes: [keyStatementCode],
    },
  })
  const result = useInsightsSnapshotOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      benchmark,
    },
  })
  const hasTimeTrending = Boolean(timeTrendingType)
  if (!survey.associatedSurvey) return <div />
  return (
    <>
      {showIndexScoreTimeTrending ? (
        <div className={classes.fullRow} id="tt-index-score-snap">
          <TTIndexScoreResponseCard {...props} />
        </div>
      ) : (
        <div className={classes.halfRow}>
          <KeyStatementContainer
            surveyUuid={survey.uuid}
            associatedSurveyUuid={survey.associatedSurvey.uuid}
            lessThanMin={responseRate.finished < survey.minShowableResults}
            filters={filters}
            keyStatementCode={keyStatementCode}
            minShowableResults={survey.minShowableResults}
          />
          <ResponseRateContainer
            survey={survey}
            filterValueUuids={filters}
            hasTimeTrending={hasTimeTrending}
          />
        </div>
      )}
      <>
        {showDetailedBreakdownTimeTrending ? (
          <>
            <TTDetailedBreakdownCard {...props} />
            <ResponseHandler {...keyStatementResult}>
              {({ statements }) => {
                return (
                  <TTScatterAndTableCard
                    {...props}
                    companyOverallPositive={Math.round(statements[0].positive)}
                  />
                )
              }}
            </ResponseHandler>
          </>
        ) : (
          <ResponseHandler {...result}>
            {({ insightsOverallIndex }) => {
              const overallPositive = Math.round(insightsOverallIndex.positive)
              const benchmarkPositive = Math.round(insightsOverallIndex.benchmarkPositive || 0)
              return (
                <DetailedBreakdownCard
                  hasTimeTrending={hasTimeTrending}
                  {...props}
                  title="Key Statement Scores By"
                  useFilterNameSuffixInTitle
                  description="See how key statement scores varied across your organization."
                  tooltip="This chart shows the key statement score across your organization."
                  overallPositiveScore={overallPositive}
                  benchmarkPositive={benchmarkPositive}
                  statementCode={keyStatementCode}
                />
              )
            }}
          </ResponseHandler>
        )}
        {showStatementsTimeTrending ? (
          <TTSnapshotStatementsCard {...props} />
        ) : (
          <>
            <StatementsContainer
              survey={survey}
              filters={filters}
              benchmark={benchmark}
              availableSurveys={availableSurveys}
              comparisonFilters={[]}
              selectedResponseTypes={[RESPONSE_TYPES.all]}
              limit={5}
              timeTrendingChartKey={TimeTrendingChartKey.EMPLOYEE_SNAPSHOT_STATEMENTS}
              timeTrendingSurveysKey={TimeTrendingSurveysKey.EMPLOYEE_SNAPSHOT_STATEMENTS}
              timeTrendingType={timeTrendingType}
            />
          </>
        )}
      </>
      {solution.insightsComments && (
        <div className={classes.fullRow}>
          <Typography>
            Go to&nbsp;
            <NavLink
              to={getInsightsPage(survey.uuid, InsightsModulesEnum.COMMENTS, survey.productType)}
            >
              &quot;Comments&quot;
            </NavLink>
            &nbsp;to see answers to your open ended questions.
          </Typography>
        </div>
      )}
      {solution.insightsKeyDemographics &&
        (showKeyDemographicsTimeTrending ? (
          <TTKeyDemographicsCard {...props} />
        ) : (
          <KeyDemographics
            survey={survey}
            filters={filters}
            surveyProductType={survey.productType}
            benchmark={benchmark}
            hasTimeTrending={hasTimeTrending}
          />
        ))}
    </>
  )
}

export default EmployeePulseSnapshot
