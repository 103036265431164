import React, { ReactElement, useContext } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  FilledInput,
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import cn from 'classnames'
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  subWeeks,
  subMonths,
  startOfMonth,
  startOfYear,
  subYears,
} from 'date-fns'
import range from 'lodash/range'

import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { gaEvent } from 'config/ga'
import { StoreContext, TimeframeSettings } from 'config/LocalStorage'

const useStyles = makeStyles(({ spacing, palette }) => ({
  groupLabel: {
    padding: spacing(),
    paddingLeft: spacing(2),
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  customDates: {
    paddingLeft: spacing(3),
  },
  datePickers: {
    marginRight: spacing(2),
    '& .MuiInputLabel-root': {
      color: palette.common.white,
    },
    '& .MuiInput-input': {
      color: palette.common.white,
    },
    '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover': {
      borderBottomColor: palette.common.white,
    },
  },
}))

const CUSTOM_TIMEFRAME = 'Custom'

const getTimeframes = (surveyStartDate: string) => {
  const now = new Date()
  const thisWeek = startOfWeek(now, { weekStartsOn: 1 })
  const thisMonth = startOfMonth(now)
  // Use string label rather than enum since the years need to be dynamically generated.
  const timeframes: Array<{ label: string; start?: Date; end?: Date; group?: string }> = [
    { label: 'All Time' },
    { label: 'This Week', start: thisWeek, group: 'WEEK' },
    {
      label: 'Last Week',
      start: subWeeks(thisWeek, 1),
      end: thisWeek,
    },
    { label: 'This Month', start: thisMonth, group: 'MONTH' },
    { label: 'Last Month', start: subMonths(thisMonth, 1), end: thisMonth },
    { label: 'Past 6 Months', start: subMonths(now, 6) },
  ]
  // Populate years since survey start year.
  const startYear = new Date(surveyStartDate).getFullYear()
  const currentYear = now.getFullYear()
  timeframes.push({ label: String(currentYear), start: startOfYear(now), group: 'YEAR' })
  range(1, currentYear - startYear + 1).forEach(increment => {
    timeframes.push({
      label: String(currentYear - increment),
      start: subYears(startOfYear(now), increment),
      end: subYears(startOfYear(now), increment - 1),
    })
  })
  // Empty Group shows a border.
  timeframes.push({ label: CUSTOM_TIMEFRAME, group: '' })
  return timeframes.map(({ label, start, end = null, group }) => {
    // Drop minutes and seconds so their value doesn't change on every render.
    start?.setMilliseconds(0)
    start?.setMinutes(0)
    return { label, value: { start, end }, group }
  })
}

const defaultTimeframeSettings = {
  timeframe: null,
  customStartDate: null,
  customEndDate: null,
}

export const getStartAndEndDateFromTimeframe = (
  timeframeSettings: TimeframeSettings,
  surveyStartDate: string,
) => {
  const { timeframe, customStartDate, customEndDate } =
    timeframeSettings || defaultTimeframeSettings
  const timeframes = getTimeframes(surveyStartDate)
  const timeFrame = timeframes.find(tf => tf.label === timeframe) || timeframes[0]
  let startDate
  let endDate
  if (timeframe === CUSTOM_TIMEFRAME) {
    startDate = customStartDate && startOfDay(new Date(customStartDate))
    endDate = customEndDate && endOfDay(new Date(customEndDate))
  } else {
    startDate = timeFrame.value.start
    endDate = timeFrame.value.end
  }
  return { startDate: startDate?.toISOString(), endDate: endDate?.toISOString() }
}

const getDropdownItems = (classes: ReturnType<typeof useStyles>, surveyStartDate: string) => {
  const dropdownItems: ReactElement[] = []
  getTimeframes(surveyStartDate).forEach(({ label, group }, idx) => {
    // Empty string group shows a border
    if (group !== undefined) {
      dropdownItems.push(
        <Typography key={group + idx} className={classes.groupLabel} color="textSecondary">
          {group}
        </Typography>,
      )
    }
    dropdownItems.push(
      <MenuItem key={label} value={label}>
        {label}
      </MenuItem>,
    )
  })
  return dropdownItems
}

type Props = {
  survey: InsightsSurvey
}
const TimeFrameFilters: React.FC<Props> = ({ survey }) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  const {
    store: { timeframeSettingsBySurvey },
    updateStore,
  } = useContext(StoreContext)
  const timeframeSettings = timeframeSettingsBySurvey[survey.uuid] || {}
  const { timeframe, customStartDate, customEndDate } = timeframeSettings
  return (
    <>
      <Typography>Time frame:</Typography>
      <FormControl variant="filled" style={{ maxWidth: 150 }} id="timeframe-selector">
        <Select
          value={timeframe || getTimeframes(survey.startDate)[0].label}
          className={classes.customDropdownSelect}
          onChange={e => {
            gaEvent({
              action: `surveySelectTimeFrame-${survey.type}`,
              category: 'Insights',
            })
            updateStore({
              timeframeSettingsBySurvey: {
                ...timeframeSettingsBySurvey,
                [survey.uuid]: {
                  ...timeframeSettings,
                  timeframe: e.target.value as string,
                },
              },
            })
          }}
          input={<FilledInput />}
        >
          {getDropdownItems(classes, survey.startDate)}
        </Select>
      </FormControl>
      {timeframe === CUSTOM_TIMEFRAME && (
        <div className={cn(classes.surveyDropdownControlsRow, classes.customDates)}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="timeframe-start-date"
              className={classes.datePickers}
              label="Start"
              value={customStartDate && new Date(customStartDate)}
              margin="normal"
              onChange={val => {
                updateStore({
                  timeframeSettingsBySurvey: {
                    ...timeframeSettingsBySurvey,
                    [survey.uuid]: {
                      ...timeframeSettings,
                      customStartDate: val,
                    },
                  },
                })
              }}
            />
            <DatePicker
              id="timeframe-end-date"
              className={classes.datePickers}
              label="End"
              value={customEndDate && new Date(customEndDate)}
              margin="normal"
              onChange={val => {
                updateStore({
                  timeframeSettingsBySurvey: {
                    ...timeframeSettingsBySurvey,
                    [survey.uuid]: {
                      ...timeframeSettings,
                      customEndDate: val,
                    },
                  },
                })
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
    </>
  )
}

export default TimeFrameFilters
