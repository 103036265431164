import {
  DataTypeCode,
  QFocus,
  QResidentFocus,
  QResidentResultsGroup,
  SurveyTypeEnum,
  SurveyProductTypeEnum,
  SurveyResponseNotificationTypeEnum,
  SurveyScoreNotificationFrequencyEnum,
  SurveyScoreNotificationTypeEnum,
  SurveyInviteMethodEnum,
  BenchmarkCodeType,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { ParticipantTypeOptionsEnum } from 'utils/generatedFrontendConstants'

export const DEFAULT_USER_TIMEZONE = 'US/Eastern'

export const ORG_SETTINGS = '/org-settings'
export const SURVEYS = '/surveys'
export const CERTIFICATION_HUB = '/certification-hub'
export const INFORM_LETTERS = '/inform-letters'
export const CULTURE_BRIEF = `${CERTIFICATION_HUB}/culture-brief/:uuid`
export const SURVEY_DETAILS = `${SURVEYS}/:uuid`
export const MONITOR = 'monitor'
export const INSIGHTS = '/insights'
export const EMPLOYEE_INSIGHTS = '/insights/employee'
export const RESIDENT_INSIGHTS = '/insights/customer'
export const EMPLOYEE_INSIGHTS_DETAILS = `${EMPLOYEE_INSIGHTS}/:uuid`
export const RESIDENT_INSIGHTS_DETAILS = `${RESIDENT_INSIGHTS}/:uuid`
export const COMMENTS = 'comments'
export const COMPARISONS = 'comparisons'
export const SNAPSHOTS = 'snapshots'
export const STATEMENTS = 'statements'
export const TAKE_ACTION = 'take-action'
export const HEATMAP = 'heatmap'
export const TESTIMONIALS = 'testimonials'
export const INDIVIDUAL_RESULTS = 'individual-results'

export enum SURVEY_URLS {
  'MONITOR' = 'monitor',
  DESIGN = 'design',
  QUESTIONS = 'questions',
  PARTICIPANTS = 'participants',
  NOTIFICATIONS = 'notifications',
  RESPONSE_NOTIFICATIONS = 'response-notifications',
  DISTRIBUTION = 'distribution',
  SUMMARY = 'summary',
}

export const URLS = {
  DASHBOARD: '/',
  DOWNLOAD: '/download',
  LOGIN: '/backend/login',
  LOGOUT: '/backend/logout',
  // leaving these for backwards compability but they are not used
  OLD_LOGIN: '/auth/login',
  OLD_LOGOUT: '/auth/logout',
  SET_PASSWORD: '/auth/set-password',
  MONITOR: {
    REPORT: '/monitor/:uuid',
    REPORTS: '/reports',
  },
  SURVEYS: {
    DASHBOARD: SURVEYS,
    CREATE: `${SURVEYS}/add`,
    MONITOR: `${SURVEY_DETAILS}/${SURVEY_URLS.MONITOR}`,
    DESIGN: `${SURVEY_DETAILS}/${SURVEY_URLS.DESIGN}`,
    QUESTIONS: `${SURVEY_DETAILS}/${SURVEY_URLS.QUESTIONS}`,
    PARTICIPANTS: `${SURVEY_DETAILS}/${SURVEY_URLS.PARTICIPANTS}`,
    NOTIFICATIONS: `${SURVEY_DETAILS}/${SURVEY_URLS.NOTIFICATIONS}`,
    RESPONSE_NOTIFICATIONS: `${SURVEY_DETAILS}/${SURVEY_URLS.RESPONSE_NOTIFICATIONS}`,
    DISTRIBUTION: `${SURVEY_DETAILS}/${SURVEY_URLS.DISTRIBUTION}`,
    SUMMARY: `${SURVEY_DETAILS}/${SURVEY_URLS.SUMMARY}`,
  },
  EMPLOYEE_INSIGHTS: {
    DASHBOARD: EMPLOYEE_INSIGHTS,
    SNAPSHOT: `${EMPLOYEE_INSIGHTS_DETAILS}/${SNAPSHOTS}`,
    STATEMENTS: `${EMPLOYEE_INSIGHTS_DETAILS}/${STATEMENTS}`,
    COMMENTS: `${EMPLOYEE_INSIGHTS_DETAILS}/${COMMENTS}`,
    COMPARISONS: `${EMPLOYEE_INSIGHTS_DETAILS}/${COMPARISONS}`,
    TAKE_ACTION: `${EMPLOYEE_INSIGHTS_DETAILS}/${TAKE_ACTION}`,
    HEATMAP: `${EMPLOYEE_INSIGHTS_DETAILS}/${HEATMAP}`,
    INDIVIDUAL_RESULTS: `${EMPLOYEE_INSIGHTS_DETAILS}/${INDIVIDUAL_RESULTS}`,
  },
  RESIDENT_INSIGHTS: {
    DASHBOARD: RESIDENT_INSIGHTS,
    SNAPSHOT: `${RESIDENT_INSIGHTS_DETAILS}/${SNAPSHOTS}`,
    STATEMENTS: `${RESIDENT_INSIGHTS_DETAILS}/${STATEMENTS}`,
    COMMENTS: `${RESIDENT_INSIGHTS_DETAILS}/${COMMENTS}`,
    COMPARISONS: `${RESIDENT_INSIGHTS_DETAILS}/${COMPARISONS}`,
    TAKE_ACTION: `${RESIDENT_INSIGHTS_DETAILS}/${TAKE_ACTION}`,
    HEATMAP: `${RESIDENT_INSIGHTS_DETAILS}/${HEATMAP}`,
    TESTIMONIALS: `${RESIDENT_INSIGHTS_DETAILS}/${TESTIMONIALS}`,
    INDIVIDUAL_RESULTS: `${RESIDENT_INSIGHTS_DETAILS}/${INDIVIDUAL_RESULTS}`,
  },
  ANALYTICS: '/analytics',
  ACTION_PLAN: '/action-plan',
  ACTION_PLANS_MANAGEMENT: '/manage-plans',
  CERTIFICATION_HUB: {
    HUB: `${CERTIFICATION_HUB}/hub`,
    CULTURE_BRIEF: `${CERTIFICATION_HUB}/culture-brief`,
  },
  INFORM_LETTERS: '/inform-letters',
  ORG_SETTINGS: {
    GENERAL: `${ORG_SETTINGS}/general`,
    CONTACTS_UPLOAD: `${ORG_SETTINGS}/contacts-upload`,
    EMPLOYEE_SURVEY_PORTAL: `${ORG_SETTINGS}/employee-survey-portal`,
    RESIDENT_SURVEY_PORTAL: `${ORG_SETTINGS}/customer-survey-portal`,
    TEAM: `${ORG_SETTINGS}/team`,
    USERS: `${ORG_SETTINGS}/team/users`,
    GROUPS: `${ORG_SETTINGS}/team/groups`,
    DATA_STRUCTURE: `${ORG_SETTINGS}/values`,
    FILTER_VALUES: `${ORG_SETTINGS}/values/:uuid`,
  },
}

export const surveyPage = (uuid: string, page: SURVEY_URLS) => `${SURVEYS}/${uuid}/${page}`

export const filterValuesPage = (uuid: string) => `${ORG_SETTINGS}/values/${uuid}`

export const BENCHMARK_TYPES = {
  INDUSTRY: 'Industry',
  INTERNAL: 'Internal',
  PAST_SURVEY: 'Past Surveys',
}

export const SURVEY_DEFAULT_MAX_PERIOD = 13
export const SURVEY_DEFAULT_START_DAYS_FROM_NOW = 14
export const SURVEY_DEFAULT_START_HOUR = 9
export const SURVEY_DEFAULT_START_MINUTE = 0
export const SURVEY_DEFAULT_END_DAYS_FROM_NOW =
  SURVEY_DEFAULT_START_DAYS_FROM_NOW + SURVEY_DEFAULT_MAX_PERIOD
export const SURVEY_DEFAULT_END_HOUR = 23
export const SURVEY_DEFAULT_END_MINUTE = 59
export const SURVEY_NAME_MIN_LENGTH = 3
export const SURVEY_CODE_MIN_LENGTH = 2
export const SURVEY_CODE_MAX_LENGTH = 64

export const QUESTION_FOCUSES = {
  // Retain the following order (ref: AI-1313).
  [QFocus.CREDIBILITY]: 'Credibility',
  [QFocus.RESPECT]: 'Respect',
  [QFocus.FAIRNESS]: 'Fairness',
  [QFocus.PRIDE]: 'Pride',
  [QFocus.CAMARADERIE]: 'Camaraderie',
  [QFocus.CUSTOM]: 'Custom',
}
export const RESIDENT_QUESTION_FOCUSES = {
  [QResidentFocus.OVERALL_SATISFACTION]: 'Overall Satisfaction',
  [QResidentFocus.MOVE_IN]: 'Move In',
  [QResidentFocus.SERVICE_AREAS]: 'Service Areas',
  [QResidentFocus.MANAGEMENT]: 'Management', // Deprecated in favor of Service Areas
  [QResidentFocus.DEMOGRAPHICS]: 'Demographics',
  [QResidentFocus.OPEN_ENDED]: 'Open Ended',
  [QResidentFocus.ONLINE_REVIEW]: 'Online Review',
  [QResidentFocus.TESTIMONIALS]: 'Marketing Testimonials',
  [QResidentFocus.CUSTOM]: 'Custom', // For dynamically adding custom questions
}

export const MARKETING_ADDON_QUESTION_FOCUSES = [
  QResidentFocus.TESTIMONIALS,
  QResidentFocus.ONLINE_REVIEW,
]

export const RESIDENT_RESULTS_GROUP_LABELS = {
  // Retain the following order (ref: AI-1313).
  [QResidentResultsGroup.OVERALL_SATISFACTION]: 'Overall Satisfaction',
  [QResidentResultsGroup.MANAGEMENT]: 'Management',
  [QResidentResultsGroup.DINING]: 'Dining',
  [QResidentResultsGroup.PERSONAL_CARE]: 'Personal Care',
  [QResidentResultsGroup.LICENSED_NURSING]: 'Licensed Nursing',
  [QResidentResultsGroup.OTHER_SERVICES]: 'Other Services',
  [QResidentResultsGroup.CUSTOM]: 'Custom',
}

export const PARTICIPANT_TYPE_OPTIONS_LABELS = {
  [ParticipantTypeOptionsEnum.CLIENT]: 'Residents',
  [ParticipantTypeOptionsEnum.REPRESENTATIVE]: 'Contacts',
  [ParticipantTypeOptionsEnum.ALL]: 'Residents & Contacts',
}

export const DATA_TYPE_NAMES: { [key in DataTypeCode]?: string } = {
  [DataTypeCode.DEPARTMENT_NAME]: 'Departments',
  [DataTypeCode.TI_BIRTH_YEAR]: 'Birth Year',
  [DataTypeCode.TI_TENURE]: 'Tenure',
  [DataTypeCode.TI_GENDER]: 'Gender',
  [DataTypeCode.TI_RACE]: 'Race',
  [DataTypeCode.TI_WORK_STATUS]: 'Work Status',
  [DataTypeCode.TI_MANAGERIAL_LEVEL]: 'Managerial Level',
  // Resident Types
  [DataTypeCode.PARTICIPANT_TYPE]: 'Resident vs. Contact Scores',
  // Either `LENGTH_OF_STAY` or `MOD_LENGTH_OF_STAY` will be in use
  // for a survey, so they may have the same value, 'Length of Stay'.
  [DataTypeCode.LENGTH_OF_STAY]: 'Length of Stay',
  [DataTypeCode.MOD_LENGTH_OF_STAY]: 'Length of Stay',
  [DataTypeCode.HEALTH]: 'Health Status',
  [DataTypeCode.SENIOR_BIRTH_YEAR]: 'Birth Year',
  [DataTypeCode.MARITAL_STATUS]: 'Marital Status',
  [DataTypeCode.LONELINESS_GROUP]: 'Loneliness Score',
  [DataTypeCode.DISCHARGE_REASON]: 'Reason for Leaving',
  [DataTypeCode.MEDICAL_NEEDS]: 'Ability to Address Medical Needs',
  [DataTypeCode.NEXT_PLACE]: 'Next Residence',
}

export const UNSAVED_CHANGES_MESSAGE = 'You have unsaved changes. Discard changes?'

export const LINEAR_QUESTION_CHOICE_OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const FILTER_NAME_MAX_LENGTH = 128
export const FILTER_ADDRESS_MAX_LENGTH = 256
export const FILTER_CITY_MAX_LENGTH = 128
export const FILTER_NICKNAME_MAX_LENGTH = 32

export const MIN_SHOWABLE_RESULTS = 5
export const MIN_SHOWABLE_RESULTS_CODE = -1000

export const RANKED_STATEMENTS_LIMIT = 5
export const RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS = 2

export const SURVEY_TEXT_KEYS = {
  WELCOME_HEADER: 'welcome_header',
  WELCOME_SUBHEADER: 'welcome_subheader',
  WELCOME_TEXT: 'welcome_text',
}

// Order in this object matters, because it defines the order they will appear in the UI
export const SURVEY_TYPE_TO_DEFAULT_LABEL = {
  [SurveyTypeEnum.TI]: 'Annual Engagement / Trust Index',
  [SurveyTypeEnum.MONTHLY]: 'Monthly Engagement',
  [SurveyTypeEnum.END_OF_EMPLOYMENT]: 'End of Employment',
  [SurveyTypeEnum.PULSE]: 'Pulse',
  [SurveyTypeEnum.CUSTOM]: 'Custom',
  [SurveyTypeEnum.RESIDENT_ENGAGEMENT]: 'Annual Engagement',
  [SurveyTypeEnum.RESIDENT_START_OF_SERVICE]: 'Start of Service',
  [SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY]: 'Monthly Engagement',
  [SurveyTypeEnum.RESIDENT_END_OF_SERVICE]: 'End of Service',
  [SurveyTypeEnum.RESIDENT_DISCHARGE]: 'Discharge',
  [SurveyTypeEnum.RESIDENT_PULSE]: 'Pulse',
  [SurveyTypeEnum.RESIDENT_CUSTOM]: 'Custom',
}

export const SURVEY_TYPE_TO_LABEL_PREFIX = {
  [SurveyTypeEnum.TI]: '',
  [SurveyTypeEnum.PULSE]: '',
  [SurveyTypeEnum.CUSTOM]: 'Employee',
  [SurveyTypeEnum.MONTHLY]: '',
  [SurveyTypeEnum.END_OF_EMPLOYMENT]: '',
  [SurveyTypeEnum.RESIDENT_ENGAGEMENT]: 'Customer',
  [SurveyTypeEnum.RESIDENT_PULSE]: 'Customer',
  [SurveyTypeEnum.RESIDENT_END_OF_SERVICE]: 'Customer',
  [SurveyTypeEnum.RESIDENT_DISCHARGE]: 'Customer',
  [SurveyTypeEnum.RESIDENT_CUSTOM]: 'Customer',
  [SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY]: 'Customer',
  [SurveyTypeEnum.RESIDENT_START_OF_SERVICE]: 'Customer',
}

export const PULSE_SURVEY_TYPES = [SurveyTypeEnum.PULSE, SurveyTypeEnum.RESIDENT_PULSE]
export const CUSTOM_SURVEY_TYPES = [SurveyTypeEnum.CUSTOM, SurveyTypeEnum.RESIDENT_CUSTOM]

export const SURVEY_PULSE_TYPE_TO_PARENT_TYPE: { [key in SurveyTypeEnum]?: SurveyTypeEnum } = {
  [SurveyTypeEnum.PULSE]: SurveyTypeEnum.TI,
  [SurveyTypeEnum.RESIDENT_PULSE]: SurveyTypeEnum.RESIDENT_ENGAGEMENT,
}

export enum ScoreTitleEnum {
  RESIDENT = 'Engagement Score',
  EMPLOYEE = 'Trust Index Score',
}

export enum LongScoreTitleEnum {
  RESIDENT = 'Customer Engagement Score',
  EMPLOYEE = 'Trust Index Score',
}

export const PRODUCT_TYPE_CONSTANTS = {
  [SurveyProductTypeEnum.EMPLOYEE]: {
    detailsPath: EMPLOYEE_INSIGHTS_DETAILS,
    insightsUrls: URLS.EMPLOYEE_INSIGHTS,
    settingsUrls: URLS.ORG_SETTINGS.EMPLOYEE_SURVEY_PORTAL,
  },
  [SurveyProductTypeEnum.RESIDENT]: {
    detailsPath: RESIDENT_INSIGHTS_DETAILS,
    insightsUrls: URLS.RESIDENT_INSIGHTS,
    settingsUrls: URLS.ORG_SETTINGS.RESIDENT_SURVEY_PORTAL,
  },
}

export const PRODUCT_TYPE_USER_FIELDS: {
  [productType in SurveyProductTypeEnum]: {
    accessToSurveyProductField: 'accessToSurveyProduct' | 'residentAccessToSurveyProduct'
    createSurveysField: 'canCreateSurveys' | 'residentCanCreateSurveys'
    surveySettingsField: 'canAccessSurveySettings' | 'residentCanAccessSurveySettings'
    insightsModulesField: 'insightsModules' | 'residentInsightsModules'
    lonelinessResultsField: undefined | 'canAccessLonelinessResults'
  }
} = {
  [SurveyProductTypeEnum.EMPLOYEE]: {
    accessToSurveyProductField: 'accessToSurveyProduct',
    createSurveysField: 'canCreateSurveys',
    surveySettingsField: 'canAccessSurveySettings',
    insightsModulesField: 'insightsModules',
    lonelinessResultsField: undefined,
  },
  [SurveyProductTypeEnum.RESIDENT]: {
    accessToSurveyProductField: 'residentAccessToSurveyProduct',
    createSurveysField: 'residentCanCreateSurveys',
    surveySettingsField: 'residentCanAccessSurveySettings',
    insightsModulesField: 'residentInsightsModules',
    lonelinessResultsField: 'canAccessLonelinessResults',
  },
}

export const PRODUCT_TYPE_LABELS = {
  [SurveyProductTypeEnum.EMPLOYEE]: 'Employee',
  [SurveyProductTypeEnum.RESIDENT]: 'Customer',
}

export const PRODUCT_TYPE_TO_OVERLAY_COLORS = {
  [SurveyProductTypeEnum.EMPLOYEE]: colors.teal,
  [SurveyProductTypeEnum.RESIDENT]: colors.peach,
}

export const PRODUCT_TYPE_TO_OVERLAY_TOOLTIP_COLORS = {
  [SurveyProductTypeEnum.EMPLOYEE]: colors.teal,
  [SurveyProductTypeEnum.RESIDENT]: colors.darkOrange,
}

export const SURVEY_TYPES_TO_PRODUCT_TYPE = {
  [SurveyTypeEnum.TI]: SurveyProductTypeEnum.EMPLOYEE,
  [SurveyTypeEnum.PULSE]: SurveyProductTypeEnum.EMPLOYEE,
  [SurveyTypeEnum.CUSTOM]: SurveyProductTypeEnum.EMPLOYEE,
  [SurveyTypeEnum.MONTHLY]: SurveyProductTypeEnum.EMPLOYEE,
  [SurveyTypeEnum.END_OF_EMPLOYMENT]: SurveyProductTypeEnum.EMPLOYEE,
  [SurveyTypeEnum.RESIDENT_ENGAGEMENT]: SurveyProductTypeEnum.RESIDENT,
  [SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY]: SurveyProductTypeEnum.RESIDENT,
  [SurveyTypeEnum.RESIDENT_START_OF_SERVICE]: SurveyProductTypeEnum.RESIDENT,
  [SurveyTypeEnum.RESIDENT_PULSE]: SurveyProductTypeEnum.RESIDENT,
  [SurveyTypeEnum.RESIDENT_END_OF_SERVICE]: SurveyProductTypeEnum.RESIDENT,
  [SurveyTypeEnum.RESIDENT_DISCHARGE]: SurveyProductTypeEnum.RESIDENT,
  [SurveyTypeEnum.RESIDENT_CUSTOM]: SurveyProductTypeEnum.RESIDENT,
}

export const getSurveyTypesByProductType = (productType: SurveyProductTypeEnum) => {
  return Object.entries(SURVEY_TYPES_TO_PRODUCT_TYPE)
    .filter(([_, value]) => value === productType)
    .map(([key, _]) => key as SurveyTypeEnum)
}

export const STATEMENT_CODES = {
  PROFIT: BenchmarkCodeType.TI_17,
  AGE: BenchmarkCodeType.TI_27,
  RACE: BenchmarkCodeType.TI_32,
  GENDER_STATEMENT: BenchmarkCodeType.TI_36,
  SEXUAL_ORIENTATION: BenchmarkCodeType.TI_42,
  EMPLOYEE_PULSE_KEY_STATEMENT: BenchmarkCodeType.TI_60,
  RESIDENT_PULSE_KEY_STATEMENT: BenchmarkCodeType.CORE_Q1_RECOMMEND,
}

type PulseType = SurveyTypeEnum.PULSE | SurveyTypeEnum.RESIDENT_PULSE

export const PRODUCT_TYPE_TO_PULSE_TYPE: { [key in SurveyProductTypeEnum]: PulseType } = {
  [SurveyProductTypeEnum.EMPLOYEE]: SurveyTypeEnum.PULSE,
  [SurveyProductTypeEnum.RESIDENT]: SurveyTypeEnum.RESIDENT_PULSE,
}

export const SURVEY_TYPE_TO_KEY_STATEMENT: { [key in PulseType]: string } = {
  [SurveyTypeEnum.PULSE]: STATEMENT_CODES.EMPLOYEE_PULSE_KEY_STATEMENT,
  [SurveyTypeEnum.RESIDENT_PULSE]: STATEMENT_CODES.RESIDENT_PULSE_KEY_STATEMENT,
}

export const SKIP_CODES_FOR_INCONSISTENT = [
  STATEMENT_CODES.PROFIT,
  STATEMENT_CODES.EMPLOYEE_PULSE_KEY_STATEMENT,
]
export const SKIP_CODES_FOR_POSITIVE = [
  STATEMENT_CODES.SEXUAL_ORIENTATION,
  STATEMENT_CODES.GENDER_STATEMENT,
  STATEMENT_CODES.AGE,
  STATEMENT_CODES.RACE,
]

export const CONTACT_EMAIL = 'hello@activatedinsights.com'
export const SUPPORT_EMAIL = 'support@activatedinsights.com'

export enum RESPONSE_TYPES {
  all = 'All Responses',
  pos = 'Positive',
  inc = 'Inconsistent',
  neg = 'Negative',
}

export const FOLLOW_STATUS = {
  all: 'Show All',
  follow: 'Following',
  notFollow: 'Not Following',
}

export enum SORT_OPTIONS {
  A_TO_Z = 'aToZ',
  LOW_TO_HIGH = 'lowToHigh',
  HIGH_TO_LOW = 'highToLow',
}

// When used in lodash `orderBy`
export enum ORDER_TYPES {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export const SURVEY_RESPONSE_NOTIFICATION_TYPES_ENUM = {
  [SurveyResponseNotificationTypeEnum.FIRST_RESPONSE]: 'First survey response',
  [SurveyResponseNotificationTypeEnum.DAILY]: 'Daily recap',
  [SurveyResponseNotificationTypeEnum.WEEKLY]: 'Weekly recap',
  [SurveyResponseNotificationTypeEnum.MONTHLY]: 'Monthly recap',
  [SurveyResponseNotificationTypeEnum.QUARTERLY]: 'Quarterly recap',
}

export const SCORE_TYPE_NOTIFICATION_TYPES_ENUM = {
  [SurveyScoreNotificationFrequencyEnum.DAILY]: 'Daily',
  [SurveyScoreNotificationFrequencyEnum.WEEKLY_MONDAYS]: 'Weekly on Monday',
  [SurveyScoreNotificationFrequencyEnum.WEEKLY_TUESDAYS]: 'Weekly on Tuesday',
  [SurveyScoreNotificationFrequencyEnum.WEEKLY_WEDNESDAYS]: 'Weekly on Wednesday',
  [SurveyScoreNotificationFrequencyEnum.WEEKLY_THURSDAYS]: 'Weekly on Thursday',
  [SurveyScoreNotificationFrequencyEnum.WEEKLY_FRIDAYS]: 'Weekly on Friday',
  [SurveyScoreNotificationFrequencyEnum.WEEKLY_SATURDAYS]: 'Weekly on Saturday',
  [SurveyScoreNotificationFrequencyEnum.WEEKLY_SUNDAYS]: 'Weekly on Sunday',
}

export const NOTIFICATION_SCORE_TYPE_LABEL = {
  [SurveyScoreNotificationTypeEnum.LOW]: 'Low Survey Score',
  [SurveyScoreNotificationTypeEnum.HIGH]: 'High Survey Score',
}

export const US_STATES = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Federated States Of Micronesia', 'FM'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'MP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Palau', 'PW'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virgin Islands', 'VI'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
].map(([name, abbreviation]) => ({ name, abbreviation }))

/**
 * Precomputed percentage color values along a gradient interpolated using the formula:
 * For [red, green, blue]:
 *   generatedColor = gradient1Color + percentage * (gradient2Color - gradient1Color)
 * Values are calculated between 3 gradient points: [#d03d3f, #ffffff, #068861]
 */

export const HEATMAP_GRADIENT_VALUES = {
  '0.00': 'rgb(208,61,63)',
  '0.01': 'rgb(209,65,67)',
  '0.02': 'rgb(210,69,71)',
  '0.03': 'rgb(211,73,75)',
  '0.04': 'rgb(212,77,79)',
  '0.05': 'rgb(213,81,83)',
  '0.06': 'rgb(214,85,87)',
  '0.07': 'rgb(215,89,90)',
  '0.08': 'rgb(216,93,94)',
  '0.09': 'rgb(217,97,98)',
  '0.10': 'rgb(218,101,102)',
  '0.11': 'rgb(219,105,106)',
  '0.12': 'rgb(220,109,110)',
  '0.13': 'rgb(220,112,114)',
  '0.14': 'rgb(221,116,118)',
  '0.15': 'rgb(222,120,122)',
  '0.16': 'rgb(223,124,126)',
  '0.17': 'rgb(224,128,130)',
  '0.18': 'rgb(225,132,134)',
  '0.19': 'rgb(226,136,137)',
  '0.20': 'rgb(227,140,141)',
  '0.21': 'rgb(228,144,145)',
  '0.22': 'rgb(229,148,149)',
  '0.23': 'rgb(230,152,153)',
  '0.24': 'rgb(231,156,157)',
  '0.25': 'rgb(232,160,161)',
  '0.26': 'rgb(233,164,165)',
  '0.27': 'rgb(234,168,169)',
  '0.28': 'rgb(235,172,173)',
  '0.29': 'rgb(236,176,177)',
  '0.30': 'rgb(237,180,181)',
  '0.31': 'rgb(238,184,184)',
  '0.32': 'rgb(239,188,188)',
  '0.33': 'rgb(240,192,192)',
  '0.34': 'rgb(241,196,196)',
  '0.35': 'rgb(242,200,200)',
  '0.36': 'rgb(243,204,204)',
  '0.37': 'rgb(243,207,208)',
  '0.38': 'rgb(244,211,212)',
  '0.39': 'rgb(245,215,216)',
  '0.40': 'rgb(246,219,220)',
  '0.41': 'rgb(247,223,224)',
  '0.42': 'rgb(248,227,228)',
  '0.43': 'rgb(249,231,231)',
  '0.44': 'rgb(250,235,235)',
  '0.45': 'rgb(251,239,239)',
  '0.46': 'rgb(252,243,243)',
  '0.47': 'rgb(253,247,247)',
  '0.48': 'rgb(254,251,251)',
  '0.49': 'rgb(255,255,255)',
  '0.50': 'rgb(255,255,255)',
  '0.51': 'rgb(250,253,252)',
  '0.52': 'rgb(245,250,249)',
  '0.53': 'rgb(240,248,245)',
  '0.54': 'rgb(235,245,242)',
  '0.55': 'rgb(230,243,239)',
  '0.56': 'rgb(225,240,236)',
  '0.57': 'rgb(219,238,232)',
  '0.58': 'rgb(214,236,229)',
  '0.59': 'rgb(209,233,226)',
  '0.60': 'rgb(204,231,223)',
  '0.61': 'rgb(199,228,220)',
  '0.62': 'rgb(194,226,216)',
  '0.63': 'rgb(189,223,213)',
  '0.64': 'rgb(184,221,210)',
  '0.65': 'rgb(179,219,207)',
  '0.66': 'rgb(174,216,203)',
  '0.67': 'rgb(169,214,200)',
  '0.68': 'rgb(164,211,197)',
  '0.69': 'rgb(158,209,194)',
  '0.70': 'rgb(153,206,191)',
  '0.71': 'rgb(148,204,187)',
  '0.72': 'rgb(143,202,184)',
  '0.73': 'rgb(138,199,181)',
  '0.74': 'rgb(133,197,178)',
  '0.75': 'rgb(128,194,174)',
  '0.76': 'rgb(123,192,171)',
  '0.77': 'rgb(118,189,168)',
  '0.78': 'rgb(113,187,165)',
  '0.79': 'rgb(108,185,161)',
  '0.80': 'rgb(103,182,158)',
  '0.81': 'rgb(97,180,155)',
  '0.82': 'rgb(92,177,152)',
  '0.83': 'rgb(87,175,149)',
  '0.84': 'rgb(82,172,145)',
  '0.85': 'rgb(77,170,142)',
  '0.86': 'rgb(72,168,139)',
  '0.87': 'rgb(67,165,136)',
  '0.88': 'rgb(62,163,132)',
  '0.89': 'rgb(57,160,129)',
  '0.90': 'rgb(52,158,126)',
  '0.91': 'rgb(47,155,123)',
  '0.92': 'rgb(42,153,120)',
  '0.93': 'rgb(36,151,116)',
  '0.94': 'rgb(31,148,113)',
  '0.95': 'rgb(26,146,110)',
  '0.96': 'rgb(21,143,107)',
  '0.97': 'rgb(16,141,103)',
  '0.98': 'rgb(11,138,100)',
  '0.99': 'rgb(6,136,97)',
  '1.00': 'rgb(6,136,97)',
}

export const INSIGHTS_COLORS = [colors.success, colors.warning, colors.danger]

export const employeeExperienceStaticText = {
  default: {
    checkedOut:
      "These employees are showing up, but don't feel connected to your culture. How can you re-engage these employees and motivate them to contribute more?",
    wantsMore:
      "These employees love your company culture, but aren't planning to stay long-term. What type of opportunities and growth are needed for these employees?",
    highRisk:
      "These employees aren't connected to your culture and aren't planning to stay long-term. Are you losing critical employees? Or is this expected turnover?",
  },
}

// this regex finds occurrences of an emoji in a string
// source: https://thekevinscott.com/emojis-in-javascript/
// eslint-disable-next-line no-misleading-character-class
export const emojiRegex = new RegExp(
  '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])',
)

export const TIME_TRENDING_LEGEND_COLORS = [
  '#48186a',
  '#3b518b',
  '#32648d',
  '#26878d',
  '#22a884',
  '#66ca5e',
  '#bade28',
  '#dfe317',
  '#fde724',
]

export const ERROR_MESSAGE = 'Oops, something went wrong! We have been alerted and will fix it!'

export const SURVEY_INVITE_METHOD_TO_LABEL = {
  [SurveyInviteMethodEnum.SMS_EMAIL]: 'Digital',
  [SurveyInviteMethodEnum.SMS_EMAIL_PHONE]: 'Digital and Phone',
  [SurveyInviteMethodEnum.PHONE]: 'Phone',
}

export enum TimeframeDropDownLocation {
  CONTROLS = 'controls',
  COMPONENT = 'component',
}

export enum TimeTrendingType {
  // eslint-disable-next-line no-shadow
  SURVEYS = 'surveys',
  TIMEFRAME = 'timeframe',
}

// Should be synced with the backend
export const CORE_Q1_RECOMMEND_QUESTION_TEXT =
  'In recommending this facility to your friends and family, how would you rate it overall?'
